import React, {useEffect, useState} from "react";
import {Container, Grid} from "@mui/material";
import styles from "../recap.module.css";
import {cartSelector, clearCart, getTotals} from "../../cart/cartSlice";
import {useDispatch, useSelector} from "react-redux";
import {importAllImages,} from "../../../common/utils/helperFunctions";
import logoBd from "../../../assets/img/logos/hexagone_blanc.png";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import {useHistory, useParams} from "react-router";
import SubmitButton from "../../../common/components/buttons/SubmitButton";
import {useGetAllAddressesQuery, usePostOrderLdaMutation} from "../../../app/services/x3Api";
import {authSelector} from "../../auth/authSlice";
import toast from "react-hot-toast";
import Spinner from "../../../common/components/spinner/Spinner";
import {usePostOrderMutation} from "../../../app/services/lumenApi";


const RecapLda = () => {
    const dispatch = useDispatch();

    const cart = useSelector(cartSelector);

    const {push} = useHistory();

    useEffect(() => {
        dispatch(getTotals());
    }, [cart, dispatch]);

    const {user} = useSelector(authSelector);

    const [triggerOrder] = usePostOrderLdaMutation()

    const [triggerPostLumen] = usePostOrderMutation()

    const [isSubmitting, setIsSubmitting] = useState(false);

    const {idsf, company} = useParams();

    const {data, isFetching, isError, error} = useGetAllAddressesQuery(
        idsf
    );

    const selectedShippingAddress = data?.adresseslivraison.adresselivraison.find(
        (address) => address.numero === "FAC"
    );

    function makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    const cartToSend = {
        id: makeid(80),
        clientCode: idsf,
        suuid: "",
        customerCompanyName: company,
        zipCode: selectedShippingAddress?.codepostal,
        deliveryAddressCode: selectedShippingAddress?.numero,
        cart: cart.cartItems.flatMap((item) =>
            item.lot.map((lot) => ({
                product: item.reference,
                quantity: lot.quantity,
                comment: lot.comment,
                lot: lot.numero,
            }))
        ),
        smileyAmount: "0",
    }

    const cartToSendLumen = {
        finalClientCode: idsf,
        finalClient: company,
        shippingAddress: selectedShippingAddress?.libelle + " " + selectedShippingAddress?.adresse + " " + selectedShippingAddress?.codepostal + " " + selectedShippingAddress?.ville,
        products: cart.cartItems.flatMap((item) =>
            item.lot.map((lot) => ({
                reference: item.reference,
                cartQuantity: lot.quantity,
                comment: lot.comment,
                lot: lot.numero,
            }))
        ),
    }


    const handleSubmitCart = async () => {


        setIsSubmitting(true);
        triggerOrder(cartToSend)
            .unwrap()
            .then((response) => {
                if (response.success === 1) {
                    triggerPostLumen(cartToSendLumen)
                        .unwrap()
                        .then((response) => {
                            push('/profile/clients')
                            dispatch(clearCart());
                            return toast.success('Commande enregistrée')
                        })
                } else {
                    setIsSubmitting(false);
                    toast.error("Quelque chose s'est mal passé.");
                }
            });
    };

    if (isSubmitting) return <Spinner/>;

    return (
        <Container maxWidth="xl">
            <div className={styles.loginContainer}>
                <div className={styles.login}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="flex-start"
                        spacing={3}
                    >
                        <Grid item lg={9}>
                            <div className={styles.topRow}>
                                <div
                                    className={styles.goBack}
                                    onClick={() => push("/lab-cart/" + idsf + "/" + company)}
                                >
                                    <ArrowBackIosNew/> Modifier mon panier
                                </div>
                            </div>
                            <h2 className={styles.title}>Récapitulatif de ma commande</h2>
                            <Grid
                                container
                                direction="column"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-evenly"
                                    alignItems="center"
                                    className={styles.cartTableHeader}
                                >
                                    <Grid item xs={6}>
                                        Produit
                                    </Grid>{" "}
                                    <Grid item xs={4}>
                                        lot
                                    </Grid>{" "}
                                    <Grid item xs={2}>
                                        Qté
                                    </Grid>{" "}
                                </Grid>
                                {cart.cartItems.map((product, index) => (
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-evenly"
                                        alignItems="center"
                                        className={styles.cartTableRow}
                                        key={index}
                                    >
                                        <Grid item xs={2}>
                                            <div className={styles.image}>
                                                <img
                                                    src={
                                                        process.env.PUBLIC_URL  + "/productsImg/" + product.reference.replace('/', '-') + ".jpg"
                                                            ? process.env.PUBLIC_URL  + "/productsImg/" + product.reference.replace('/', '-') + ".jpg"
                                                            : process.env.PUBLIC_URL  + "/productsImg/" + product.reference.replace('/', '-') + ".png"
                                                                ? process.env.PUBLIC_URL  + "/productsImg/" + product.reference.replace('/', '-') + ".png"
                                                                : logoBd
                                                    }
                                                    alt={product.reference}
                                                />
                                            </div>
                                        </Grid>{" "}
                                        <Grid item xs={4}>
                                            <h4 className={styles.ref}>{product.reference}</h4>
                                            <p className={styles.productDesignation}>
                                                {product.reference === '31_322_231_01-2G'
                                                    ? 'Ti-Base à vissage angulé avec sa vis pour pilier conique Ø4 mm'
                                                    :
                                                    product.reference === '31_323_232_01-2G'
                                                        ? 'Ti-Base à vissage angulé avec sa vis pour pilier conique Ø 4,9 mm'
                                                        :
                                                        product.designation}
                                            </p>
                                        </Grid>{" "}
                                        <Grid
                                            container
                                            direction="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            xs={6}
                                            className={`${styles.cartTableRow} ${product.reference}`}
                                            key={index}
                                        >
                                            {product.lot.map((l) => (
                                                <Grid
                                                    container
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    className={`${styles.cartTableRow} ${product.reference}`}
                                                    key={index}
                                                >
                                                    <Grid item xs={8}>
                                                        {l.numero}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {l.quantity}
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>

                                    </Grid>
                                ))}
                                <div>
                                    <SubmitButton
                                        buttonText="Passer Commande"
                                        onClick={handleSubmitCart}
                                        disabled={isSubmitting}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <div className={styles.client}>
                                <h3>Client : </h3>
                                <div className={styles.clientInfos}>
                                    <p>
                                        {idsf} - {company}
                                    </p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Container>
    );
};

export default RecapLda;
