import slider2 from "../../../assets/img/heroSlider/slider2.png"
import sliderATP from "../../../assets/img/heroSlider/slider-ATP.jpg"
import sliderGalaxy from "../../../assets/img/heroSlider/galaxy.jpg"
import sliderssa from "../../../assets/img/heroSlider/slider-SSA.jpg"
import slidersmilers from "../../../assets/img/heroSlider/slider-smilers.jpg"
import slidersbds from "../../../assets/img/heroSlider/slide-BDA.jpg"

export const heroSliderData = [
    {
        "id": "1",
        "title": "Biotech Dental ",
        "url": "https://www.biotech-dental.com/",
        "bgImage": slider2
    },
    {
        "id": "2",
        "title": "LaGalaxy by Biotech Dental",
        "url": "https://www.biotechgalaxy.com/",
        "bgImage": sliderGalaxy
    },
    {
        "id": "3",
        "title": "ATP38",
        "url": "https://www.atp38.com/",
        "bgImage": sliderATP
    },
    {
        "id": "4",
        "title": "Omnipost & SSA-GF",
        "url": "https://www.biotech-dental.com/",
        "bgImage": sliderssa
    },
    {
        "id": "5",
        "title": "Smilers",
        "url": "https://eshop.smilers.com/",
        "bgImage": slidersmilers
    },
    {
        "id": "6",
        "title": "Biotech Dental Academy",
        "url": "https://academy.biotech-dental.com/",
        "bgImage": slidersbds
    }
]
