import {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {FaFacebookF, FaInstagram, FaLinkedin, FaYoutube} from "react-icons/fa";
import {IoIosArrowRoundUp, IoIosAt, IoIosCall, IoIosMap, IoMdGlobe} from "react-icons/io";
import {animateScroll} from "react-scroll";
import logoBdWhite from "../../../assets/img/logos/logo_white.png";
import {Link} from "react-router-dom";
import CGV from "../../../assets/pdf/cgv.pdf"
import smileys from "../../../assets/pdf/smileys.pdf"
import mentions from "../../../assets/pdf/mentions_legales.pdf"

const FooterTwo = ({footerBgClass}) => {
    const [scroll, setScroll] = useState(0);
    const [top, setTop] = useState(0);

    useEffect(() => {
        setTop(100);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        animateScroll.scrollToTop({
            duration: 0
        });
    };

    const handleScroll = () => {
        setScroll(window.scrollY);
    };
    return (
        <footer
            className={`space-pt--100 space-pb--50 ${
                footerBgClass ? footerBgClass : "bg-color--black"
            }`}
        >
            <Container className="wide">
                <Row>
                    <Col className="footer-single-widget space-mb--50">
                        {/* logo */}
                        <div className="logo space-mb--35">
                            <Link to="/">
                                <img
                                    src={
                                        logoBdWhite
                                    }
                                    className="img-fluid"
                                    alt=""
                                /></Link>
                        </div>
                    </Col>

                    <Col className="footer-single-widget space-mb--50">
                        <h5 className="footer-single-widget__title">Contactez-nous</h5>
                        <hr className="solid"/>
                        <nav className="footer-single-widget__nav">
                            <ul>
                                <li>
                                    <a href="/#"><IoIosMap/> 305 All. de Craponne <br/>13300 Salon-de-Provence</a>
                                </li>
                                <li>
                                    <a href="tel:+33490446060" target="_blank" rel="noreferrer"><IoIosCall/> +33 4 90 44
                                        60 60</a>
                                </li>
                                <li>
                                    <a href="mailto:info@biotech-dental.com"
                                       target="_blank" rel="noreferrer"><IoIosAt/> info@biotech-dental.com</a>
                                </li>
                                <li>
                                    <a href="https://www.biotech-dental.com/"
                                       target="_blank" rel="noreferrer"><IoMdGlobe/> biotech-dental.com</a>
                                </li>
                            </ul>
                        </nav>
                    </Col>

                    <Col className="footer-single-widget space-mb--50">
                        <h5 className="footer-single-widget__title">Suivez-nous</h5>
                        <hr className="solid"/>
                        <nav className="footer-single-widget__nav footer-single-widget__nav--social">
                            <ul>
                                <li>
                                    <a href="https://fr-fr.facebook.com/GroupeBiotechDental/" target="_blank"
                                       rel="noreferrer">
                                        <FaFacebookF/> Facebook
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/biotechdental/?hl=fr" target="_blank"
                                       rel="noreferrer">
                                        <FaInstagram/> Instagram
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/channel/UC1hN7vhPr-5x-ELahIK2GkA" target="_blank"
                                       rel="noreferrer">
                                        <FaYoutube/> Youtube
                                    </a>
                                </li>
                                <li>
                                    <a href="https://fr.linkedin.com/company/biotech-dental" target="_blank"
                                       rel="noreferrer">
                                        <FaLinkedin/> Linkedin
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </Col>

                    <Col className="footer-single-widget space-mb--50">
                        <h5 className="footer-single-widget__title">Mentions utiles</h5>
                        <hr className="solid"/>
                        <nav className="footer-single-widget__nav">
                            <ul>
                                <li>
                                    <a href={mentions} target="_blank" rel="noreferrer">Mentions légales</a>
                                </li>
                                <li>
                                    <a href={CGV} target="_blank" rel="noreferrer">Conditions générales de vente</a>
                                </li>
                                <li>
                                    <a href={smileys} target="_blank" rel="noreferrer">Réglement du programme
                                        Smileys</a>
                                </li>
                                <li>
                                    <a href="/#" target="_blank" rel="noreferrer">Politique de cookies</a>
                                </li>
                            </ul>
                        </nav>
                    </Col>
                </Row>
                <Row>

                    <div className="footer-single-widget__copyright">
                        Copyright  &copy; {new Date().getFullYear() + " "}
                        Biotech Dental.
                        Tous droits réservés
                    </div>


                </Row>
            </Container>
            <button
                className={`scroll-top ${scroll > top ? "show" : ""}`}
                onClick={() => scrollToTop()}
            >
                <IoIosArrowRoundUp/>
            </button>
        </footer>
    );
};

export default FooterTwo;
