import React from "react";
import Container from "@mui/material/Container";
import {SectionTitleOne} from "../components/SectionTitle";
import {Row} from "react-bootstrap";
import CategoryGrid from "../components/categoryGrid/CategoryGrid"
import HeroSlider from "../components/heroSlider/HeroSlider";
import {heroSliderData} from "../components/heroSlider/heroSlider-data";

const Home = () => {

    return (
        <>
            {/* Hero Slider */}
            <HeroSlider sliderData={heroSliderData}/>
            <div className="space-mb--r100"></div>
            {/* category grid */}
            <div className="section-title-container">
                <Container>
                    <Row>
                        <div className="col-lg-12">
                            <SectionTitleOne title="Nos produits et solutions"/>
                        </div>
                    </Row>
                </Container>
            </div>
            <div className="space-mb--r50"></div>
            <CategoryGrid spaceBottomClass="space-mb--r100"/>
        </>
    );
};

export default Home;
