import React from "react";
import Container from "@mui/material/Container";
import {Redirect, Route, Switch} from "react-router";
import Header from "../../src/common/components/header/Header";
import Footer from "../../src/common/components/footer/Footer";
import Home from "../../src/common/pages/Home";
import Cart from "../../src/features/cart/Cart";
import NotFound from "../../src/common/pages/NotFound";
import {Toaster} from "react-hot-toast";
import ProfileDashboard from "../../src/features/profile/ProfileDashboard";
import SessionWishlist from "../../src/features/wishlist/SessionWishlist";
import StoredWishlist from "../../src/features/wishlist/StoredWishlist";
import Login from "../../src/features/auth/Login";
import Register from "../../src/features/auth/Register";
import PrivateRoute from "../../src/common/components/PrivateRoute";
import {useSelector} from "react-redux";
import Spinner from "../../src/common/components/spinner/Spinner";
import LoginSSO from "../../src/features/auth/LoginSSO";
import AddressForm from "../../src/features/addresses/AddressForm";
import Shipment from "../../src/features/shipments/Shipment";
import Recap from "../../src/features/checkout/Recap";
import ProductSearchResults from "../../src/features/products/ProductsSearchResults";
import Catalogue from "../features/catalogue/Catalogue";
import ScrollToTop from "../common/utils/scrollTop";
import OneProduct from "../features/products/OneProduct";
import ShipmentWeb from "../features/shipments/ShipmentWeb";
import AskSignUp from "../features/auth/askSignUp";
import AdminLogin from "../features/auth/AdminLogin";
import LabCart from "../features/cart/LabCart";
import LDACart from "../features/cart/LDA/LDACart";
import RecapLda from "../features/checkout/LDA/RecapLDA";
import ShipmentLda from "../features/shipments/LDA/ShipmentLda";

const App = () => {

    const isX3ApiLoading = useSelector((state) =>
        Object.values(state.x3Api.queries).some(
            (entry) => entry.status === "pending" && entry.endpointName !== "getProductsBySearchQuery" && entry.endpointName !== 'getStockLDAQuery' && entry.endpointName !== "getProductsByCode" && entry.endpointName !== "getProductsBySearchQueryTop"
        )
    );

    const isX3ApiMutating = useSelector((state) =>
        Object.values(state.x3Api.mutations).some(
            (entry) =>
                entry.status === "pending" &&
                entry.endpointName === "postGetProductsBycatalogue"
        )
    );

    return (
        <React.Fragment>
            <div>
                <Toaster/>
            </div>
            <ScrollToTop/>
            <Header/>

            <main className="mt-lg-5 mb-lg-5"

            >
                <Container maxWidth={false} disableGutters={true}>
                    {isX3ApiLoading || isX3ApiMutating ? (
                        <Spinner/>
                    ) : (
                        <>
                            <Switch>
                                <PrivateRoute exact path="/cart" component={Cart}/>
                                <PrivateRoute exact path="/lab-cart/:idsf/:company" component={LDACart}/>
                                <PrivateRoute exact path="/lab-cart/recap/:idsf/:company" component={RecapLda}/>
                                <PrivateRoute exact path="/recap" component={Recap}/>
                                <PrivateRoute exact path="/whishlist" component={SessionWishlist}/>
                                <Route
                                    exact
                                    path="/catalogue/:niveau1/:niveau2?/:niveau3?/:niveau4?"
                                    component={Catalogue}
                                />
                                <Route
                                    exact
                                    path="/search-results"
                                    component={ProductSearchResults}
                                />
                                <PrivateRoute
                                    exact
                                    path="/wishlist/:id"
                                    component={StoredWishlist}
                                />
                                <Route
                                    exact
                                    path="/product/:reference"
                                    component={OneProduct}
                                />
                                <PrivateRoute
                                    exact
                                    path="/profile/:tab"
                                    component={ProfileDashboard}
                                />
                                <PrivateRoute
                                    exact
                                    path="/profile/address/:id"
                                    component={AddressForm}
                                />
                                <PrivateRoute
                                    exact
                                    path="/profile/shipments/:id"
                                    component={Shipment}
                                />
                                <PrivateRoute
                                    exact
                                    path="/profile/shipments-lda/:id"
                                    component={ShipmentLda}
                                />
                                <PrivateRoute
                                    exact
                                    path="/profile/shipmentsWeb/:id"
                                    component={ShipmentWeb}
                                />
                                <Route exact path="/ask-registration" component={AskSignUp}/>
                                <Route exact path="/login-sso" component={LoginSSO}/>
                                <Route exact path="/not-found" component={NotFound}/>
                                <Route exact path="/login" component={Login}/>
                                <Route exact path="/register" component={Register}/>
                                <Route exact path="/" component={Home}/>
                                <Route exact path="/admin/login" component={AdminLogin}/>
                                <Redirect to="/not-found"/>
                            </Switch>
                        </>
                    )}
                </Container>
            </main>
            <Footer/>
        </React.Fragment>
    );
};

export default App;
