import React from "react";
import {Grid, Typography} from "@mui/material";
import {Box} from "@mui/system";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {useSelector} from "react-redux";
import {authSelector} from "../auth/authSlice";
import styles from "./profile.module.css";
import {useGetSmileysQuery} from "../../app/services/x3Api";

const Profile = () => {
    const Item = styled(Paper)(({theme}) => ({
        ...theme.typography.body2,
        padding: "25px",
        textAlign: "center",
        color: theme.palette.text.secondary,
        height: "100%",
    }));

    const {user} = useSelector(authSelector);

    const {data, isFetching, isError, error} = useGetSmileysQuery(
        user.suid
    );

    if (isFetching) return <></>;

    if (isError) return <div>{error}</div>;

    return (
        <>
            <Box sx={{flexGrow: 1}} bgcolor="#f7f7f7" p={3}>
                {" "}
                <div>
                    <h2 className={styles.title}>Mon profil</h2>
                </div>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    className={styles.topRow}
                >
                    <Grid item md={6}>
                        <Item>
                            <h4 className="mb-4">INFORMATIONS</h4>
                            <Typography>Raison sociale : {user.raisonsociale}</Typography>
                            <Typography>Email : {user.email}</Typography>
                            <Typography>Numéro client : {user.codeclientGC}</Typography>
                        </Item>
                    </Grid>
                    <Grid item md={6}>
                        <Item>
                            <h4 className="mb-4">MES SMILEYS</h4>
                            <Typography>{data ? data.result : '0 Smileys'}</Typography>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default Profile;
