import React, {useState} from "react";
import {
    addCommentLda,
    decreaseQuantityLda,
    increaseQuantityLda,
    removeFromCartLda,
    updateQuantityLda,
} from "../cartSlice";
import {useDispatch} from "react-redux";
import toast from "react-hot-toast";
import styles from "../cartQuantityHandler.module.css";
import {Grid} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

const CartQuantityHandlerLda = ({
                                    cart,
                                    product,
                                    lot,
                                    p,
                                    index
                                }) => {

    const dispatch = useDispatch();

    const checkStock = (v) => lot.find((l) => l.name === v)

    const [quantity, setQuantity] = useState(p?.quantity ?? 0);
    const [selectedLot, setSelectedLot] = useState(p?.numero ?? "");
    const [selectedStock, setSelectedStock] = useState(p?.numero ? checkStock(p.numero).availableQuantity : "");
    const [comment, setComment] = useState(p?.comment ?? "")
    const handleRemoveFromCart = (product) => {
        dispatch(removeFromCartLda(product));
    };

    const handleSetComment = (e) => {
        setComment(e.target.value)
        dispatch(addCommentLda({product: product, lot: selectedLot, comment: comment}))
    }
    const handleDecreaseQuantity = (product) => {
        if (!selectedLot || selectedLot === "") {
            return toast.error("Merci de sélectionner un lot");
        }
        if (quantity > 1) {
            const newQuantity = parseInt(quantity) - 1;
            setQuantity(newQuantity);
            dispatch(decreaseQuantityLda({product: product, lot: selectedLot}));
        }
    };
    const handleIncreaseQuantity = (product) => {
        if (!selectedLot || selectedLot === "") {
            return toast.error("Merci de sélectionner un lot");
        }
        const lotExist = product.lot.find(
            (item) => item.numero === selectedLot
        );
        if (lotExist.quantity + 1 <= selectedStock) {
            const newQuantity = parseInt(quantity) + 1;
            setQuantity(newQuantity);
            dispatch(increaseQuantityLda({product: product, lot: selectedLot}));
        } else {
            return toast.error("Ce lot n'a pas assez de produits disponibles");
        }
    };

    const handleInputChange = (quantity) => {
        setQuantity(quantity);
    };

    const handleQuantityChange = (product, quantity) => {
        if (isNaN(quantity) || !quantity.length) {
            return toast.error("Merci d'entrer une quantité valide.");
        }
        if (!selectedLot || selectedLot === "") {
            return toast.error("Merci de sélectionner un lot");
        }
        if (quantity <= selectedStock) {
            console.log(quantity)
            const updatedProduct = {
                ...product,
                lot: {
                    quantity: parseInt(quantity),
                    numero: selectedLot
                },
            };
            dispatch(updateQuantityLda(updatedProduct));
        } else {
            return toast.error("Ce lot n'a pas assez de produits disponibles");
        }
    };

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            className={`${styles.cartTableRow} ${product.reference}`}
            key={index}
        >
            <Grid item xs={3} className={styles.commentField}>
                {p.numero}
            </Grid>
            <Grid item xs={2}>
                <>
                    <div className={styles.quantityInput}>
                        <button onClick={() => handleDecreaseQuantity(product)}>
                            {" "}
                            <RemoveIcon fontSize="medium"/>
                        </button>
                        <input
                            value={quantity}
                            onChange={(e) => handleInputChange(e.target.value)}
                            onKeyUp={(e) => handleQuantityChange(product, e.target.value)}
                        ></input>{" "}
                        <button onClick={() => handleIncreaseQuantity(product)}>
                            {" "}
                            <AddIcon fontSize="medium"/>
                        </button>
                    </div>
                </>
            </Grid>
            <Grid item xs={2} className={styles.txtCenter}>
                {selectedStock}
            </Grid>
            <Grid item xs={4} className={styles.commentField}>
                <input value={comment} onChange={handleSetComment}/>
            </Grid>
            <Grid item xs={1} className={styles.txtCenter}>
                {cart.isLoadingQuantity ? (
                    <></>
                ) : (
                    <button
                        className={styles.deleteButton}
                        onClick={() => handleRemoveFromCart({product: product, lot: p.numero})}
                    >
                        <CloseIcon/>
                    </button>
                )}
            </Grid>
        </Grid>
    );
};
export default CartQuantityHandlerLda;
