import {Col, Container, Row} from "react-bootstrap";
import {MdApps, MdList, MdViewComfy} from "react-icons/md";
import {setActiveLayout} from "../../utils/productsFunctions";
import {useSelector} from "react-redux";
import {authSelector} from "../../../features/auth/authSlice";

const ShopHeader = ({
                        getFilterSortParams,
                        getLayout,
                        layoutClass,
                        listMode
                    }) => {

    const {isAuth} = useSelector(authSelector);

    return (
        <div className="shop-header">
            <Container className={layoutClass ? layoutClass : ""}>
                <Row className="align-items-center">
                    <Col md={5} className="text-center text-md-left">
                    </Col>

                    <Col md={7}>
                        <div className="shop-header__filter-icons justify-content-center justify-content-md-end">
                            <div className="single-icon filter-dropdown">
                                <select
                                    onChange={(e) =>
                                        getFilterSortParams("filterSort", e.target.value)
                                    }
                                >
                                    <option value="default">Tri par défaut</option>
                                    {isAuth && (
                                        <option value="priceHighToLow">Prix - Du plus cher au moins cher</option>)}
                                    {isAuth && (
                                        <option value="priceLowToHigh">Prix - Du moins cher au plus cher</option>)}
                                    <option value="refHighToLow">Référence - De A à Z</option>
                                    <option value="refLowToHigh">Référence - De Z à A</option>

                                </select>
                            </div>

                            <div className="single-icon grid-icons d-none d-lg-block">
                                <button
                                    onClick={(e) => {
                                        getLayout("grid three-column");
                                        setActiveLayout(e);
                                    }}
                                >
                                    <MdApps/>
                                </button>

                                <button
                                    className="active"
                                    onClick={(e) => {
                                        getLayout("grid four-column");
                                        setActiveLayout(e);
                                    }}
                                >
                                    <MdViewComfy/>
                                </button>
                                {listMode === false ? (
                                    ""
                                ) : (
                                    <button
                                        onClick={(e) => {
                                            getLayout("list");
                                            setActiveLayout(e);
                                        }}
                                    >
                                        <MdList/>
                                    </button>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ShopHeader;
