import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Grid} from "@mui/material";
import logoBd from "../../../assets/img/logos/hexagone_blanc.png";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import WarningIcon from "@mui/icons-material/Warning";
import styles from "./searchProducts.module.css";
import {importAllImages} from "../../../common/utils/helperFunctions";
import {authSelector} from "../../auth/authSlice";
import {addToCart, addToCartLda, cartSelector} from "../cartSlice";
import SubmitButton from "../../../common/components/buttons/SubmitButton";
import {x3Api} from "../../../app/services/x3Api";

/* ------------------------------------------------------------------------- */

/* ------------------------------------------------------------------------- */
function SearchProducts() {
    const dispatch = useDispatch();

    const auth = useSelector(authSelector);
    const cart = useSelector(cartSelector);

    const [showSearchBar, setShowSearchBar] = useState(false);


    // ref on div bloc
    let ref = useRef(null);
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setShowSearchBar(false);
        }
    };
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    const [isResearchGlobal, setIsResearchGlobal] = useState(true);

    const [callRGlobal, setCallRGlobal] = useState(false);

    // - handling global research -
    const [globalField, setGlobalField] = useState("");
    const [items, setItems] = useState(null);
    const [animeLoading, setAnimeLoading] = useState(false);

    const [trigger, resultGlobal] =
        x3Api.endpoints.getProductsBySearchQueryTop.useLazyQuery();

    // - handling research by code product -
    const [codeField, setCodeField] = useState("");
    const [itemsByCode, setItemsByCode] = useState(null);
    const [animeLoadingC, setAnimeLoadingC] = useState(false);
    const [timer, setTimer] = useState(null);

    useEffect(() => {
        !resultGlobal.isLoading && resultGlobal.data && setItems(resultGlobal.data);
        !resultGlobal.data && setItems(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultGlobal.isLoading, resultGlobal.data, items, resultGlobal.isError]);

    const handleGlobalField = (value) => {
        setGlobalField(value);
        setIsResearchGlobal(true);
        setCallRGlobal(true);

        if (value !== "0" && value.length > 0) {
            clearTimeout(timer);

            const newTimer = setTimeout(() => {
                trigger(encodeURI(value));
            }, 400);

            setTimer(newTimer);
            setAnimeLoading(true);
            setShowSearchBar(true);
        } else {
            setAnimeLoading(false);
            setShowSearchBar(false);
        }
    };



    const handleAddToCart = async (product) => {
        // Add a puuid for each items
        dispatch(addToCartLda(product))
        setShowSearchBar(false);
        setGlobalField("");
        setCodeField("");
    };

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
            >
                <Grid item xs={12}>
                    <div className={styles.searchContainer} ref={ref}>
                        <div className={styles.selectCell}>
                            <input
                                autoComplete="off"
                                type="text"
                                name="text"
                                placeholder="Recherche générique"
                                className={styles.searchInput}
                                value={globalField}
                                onChange={(e) => handleGlobalField(e.target.value)}
                            />

                            <span className={styles.anime}>
                  {animeLoading ? (
                      <CircularProgress color="inherit"/>
                  ) : (
                      <SearchIcon className={styles.searchIcon}/>
                  )}
                </span>
                        </div>
                        <Grid item xs={12}>
                            <div
                                className={
                                    showSearchBar ? styles.resultsContainer : styles.none
                                }
                            >
                <span className={styles.closeSearch}>
                  <CloseIcon onClick={() => setShowSearchBar(false)}/>
                </span>
                                <div className={styles.resultsBox}>
                                    {callRGlobal && !resultGlobal.isError && !items ? (
                                        <h5 style={{textTransform: "none"}}>
                                            {" "}
                                            Aucun produit ne correspond à ces critères.
                                        </h5>
                                    ) : null}


                                    {/* TODO: gerer correctement l'erreur impossible de lire la propriété article puis non présent dans le body de la reponse api*/}
                                    {callRGlobal && resultGlobal.isError ? (
                                        <h5 style={{textTransform: "none"}}>
                                            <WarningIcon fontSize="medium"/>
                                            Une erreur s'est produit lors de la recherche.
                                        </h5>
                                    ) : null}

                                    {callRGlobal && resultGlobal.data?.success === false ? (
                                        <h5 style={{textTransform: "none"}}>
                                            <WarningIcon fontSize="medium"/>
                                            Une erreur s'est produit lors de la recherche.
                                        </h5>
                                    ) : null}

                                    {resultGlobal.data?.length > 0 &&
                                        isResearchGlobal &&
                                        items &&
                                        items.map((product, index) => (
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="start"
                                                alignItems="center"
                                                className={styles.cartTableRow}
                                                key={index}
                                            >
                                                <Grid item xs={3}>
                                                    <div className={styles.image}>
                                                        <img
                                                            src={
                                                                process.env.PUBLIC_URL  + "/productsImg/" + product.reference.replace('/', '-') + ".jpg"
                                                                    ? process.env.PUBLIC_URL  + "/productsImg/" + product.reference.replace('/', '-') + ".jpg"
                                                                    : process.env.PUBLIC_URL  + "/productsImg/" + product.reference.replace('/', '-') + ".png"
                                                                        ? process.env.PUBLIC_URL  + "/productsImg/" + product.reference.replace('/', '-') + ".png"
                                                                        : logoBd
                                                            }
                                                            alt={product.reference}
                                                        />
                                                    </div>
                                                </Grid>

                                                <Grid item xs={5}>
                                                    <h4 className={styles.ref}>{product.reference}</h4>
                                                    <p className={styles.productDesignation}>
                                                        {product.designation}
                                                    </p>
                                                    <div className={styles.containerCatalog}>
                                                        {product.catalogueniv1 !== "" &&
                                                            product.catalogueniv1 !== null && (
                                                                <div className={styles.catalog}>
                                                                    <span>{product.catalogueniv1}</span>
                                                                </div>
                                                            )}
                                                        {product.catalogueniv2 !== "" &&
                                                            product.catalogueniv2 !== null &&
                                                            product.catalogueniv2 !==
                                                            product.catalogueniv1 && (
                                                                <div className={styles.catalog}>
                                                                    <span> - {product.catalogueniv2}</span>
                                                                </div>
                                                            )}
                                                        {product.catalogueniv3 !== "" &&
                                                            product.catalogueniv3 !== null &&
                                                            product.catalogueniv3 !==
                                                            product.catalogueniv2 && (
                                                                <div className={styles.catalog}>
                                                                    <span> - {product.catalogueniv3}</span>
                                                                </div>
                                                            )}
                                                    </div>
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <SubmitButton buttonText="Ajouter" onClick={() => handleAddToCart(product)}/>
                                                </Grid>
                                            </Grid>
                                        ))}
                                </div>
                            </div>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default SearchProducts;