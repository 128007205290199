import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import logoBd from "../../../assets/img/logos/hexagone_blanc.png";
import {Grid} from "@mui/material";
import styles from "./cartRowFull.module.css";
import {cartSelector, removeFromCart} from "../cartSlice";
import {importAllImages} from "../../../common/utils/helperFunctions";
import {useGetStockLDAQuery} from "../../../app/services/x3Api";
import CartQuantityHandlerLda from "./CartQuantityHandlerLda";
import CartQuantityHandlerLdaEmpty from "./CartQuantityHandlerLdaEmpty";
import CloseIcon from "@mui/icons-material/Close";
/* ------------------------------------------------------------------------- */


const CartRowFull = ({product, index}) => {
    const cart = useSelector(cartSelector);
    const {data} = useGetStockLDAQuery(product.reference);

    const [nbEmptyRows, setNbEmptyRows] = useState(1);
    const handleRemoveRow = () => {
        setNbEmptyRows(nbEmptyRows - 1);
    };

    const displayEmptyRow = () => {
        const emptyRows = [];
        for (let i = 0; i < nbEmptyRows; i++) {
            emptyRows.push(
                <CartQuantityHandlerLdaEmpty
                    handleRemoveRow={handleRemoveRow}
                    index={i}
                    product={product}
                    lot={data.batches.batch}
                    cart={cart}
                />
            );
        }
        return emptyRows;
    };

    const handlePushLine = () => {
        setNbEmptyRows(nbEmptyRows + 1);
    };

    const displayFullRow = () => {

        return product?.lot?.map((p, index) => (
            <CartQuantityHandlerLda
                index={index}
                product={product}
                p={p}
                lot={data.batches.batch}
                cart={cart}
            />
        ));
    };

    const dispatch = useDispatch()

    const handleRemoveProduct = () => {
        dispatch(removeFromCart(product))
    }

    if (!data) return null;

    return (
        <>
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                className={`${styles.cartTableRow} ${product.reference}`}
                key={index}
            >
                <Grid item xs={1}>
                    <div className={styles.blockImg}>
                        <img
                            src={
                                process.env.PUBLIC_URL  + "/productsImg/" + product.reference.replace('/', '-') + ".jpg"
                                    ? process.env.PUBLIC_URL  + "/productsImg/" + product.reference.replace('/', '-') + ".jpg"
                                    : process.env.PUBLIC_URL  + "/productsImg/" + product.reference.replace('/', '-') + ".png"
                                        ? process.env.PUBLIC_URL  + "/productsImg/" + product.reference.replace('/', '-') + ".png"
                                        : logoBd
                            }
                            alt={product.reference}
                        />
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <h4 className={styles.ref}>{product.reference}</h4>
                    <p className={styles.productDesignation}>{product.designation}</p>
                    <div className={styles.containerCatalog}>
                        {product.catalogueniv1 !== "" &&
                            product.catalogueniv1 !== null && (
                                <div className={styles.catalog}>
                                    <span>{product.catalogueniv1}</span>
                                </div>
                            )}
                        {product.catalogueniv2 !== "" &&
                            product.catalogueniv2 !== null &&
                            product.catalogueniv2 !== product.catalogueniv1 && (
                                <div className={styles.catalog}>
                                    <span> - {product.catalogueniv2}</span>
                                </div>
                            )}
                        {product.catalogueniv3 !== "" &&
                            product.catalogueniv3 !== null &&
                            product.catalogueniv3 !== product.catalogueniv2 && (
                                <div className={styles.catalog}>
                                    <span> - {product.catalogueniv3}</span>
                                </div>
                            )}
                    </div>
                </Grid>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    xs={8}
                    className={`${styles.cartTableRow} ${product.reference}`}
                    key={index}
                >
                    {displayFullRow()}
                    {displayEmptyRow()}
                    {!product?.lot?.length && nbEmptyRows === 0 ?
                            <Grid item xs={12} className={styles.txtEnd}>
                                <button
                                    className={styles.deleteButton}
                                    onClick={handleRemoveProduct}
                                >
                                    <CloseIcon/>
                                </button>
                            </Grid>
                        :
                        null
                    }
                </Grid>
                <button
                    onClick={handlePushLine}
                    className={`${styles.addRowLda}`}
                >
                    + Ajouter un lot
                </button>
            </Grid>

        </>
    );
};

export default CartRowFull;