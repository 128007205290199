import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {ROOT_URL_LUMEN} from "../../common/utils/apiConstants";

const baseQuery = fetchBaseQuery({
    baseUrl: ROOT_URL_LUMEN,
    prepareHeaders: (headers, {getState}) => {
        const lumenToken = getState().auth.lumenToken;
        if (lumenToken) {
            headers.set("authorization", `Bearer ${lumenToken}`);
        }
        return headers;
    },
});

export const lumenApi = createApi({
    reducerPath: "lumenApi",
    baseQuery: baseQuery,
    tagTypes: ["Wishlists", "Auth", "Orders"],
    endpoints: (build) => ({
        postLoginLumen: build.mutation({
            query(body) {
                return {
                    url: "login-sso",
                    method: "POST",
                    body,
                };
            },
        }),
        postAdminLogin: build.mutation({
            query(body) {
                return {
                    url: "login-admin",
                    method: "POST",
                    body,
                };
            },
        }),
        postReverseLogin: build.mutation({
            query(body) {
                return {
                    url: "reverse-login-sso",
                    method: "POST",
                    body,
                };
            },
        }),
        getLogout: build.query({
            query: () => "logout",
        }),
        getRefreshJwt: build.query({
            query: () => "logout",
        }),
        postOrderConfirm: build.mutation({
            query(body) {
                return {
                    url: "order",
                    method: "POST",
                    body,
                };
            },
        }),
        getWishlists: build.query({
            query: () => "wishlist",
            providesTags: (result) =>
                result
                    ? [
                        result.wishlist.map(({id}) => ({type: "Wishlists", id})),
                        {type: "Wishlists", id: "LIST"},
                    ]
                    : [{type: "Wishlists", id: "LIST"}],
        }),
        getWishlist: build.query({
            query: (id) => `wishlist/${id}`,
            providesTags: (result, error, id) => [{type: "Wishlists", id}],
        }),
        postWishlist: build.mutation({
            query(body) {
                return {
                    url: "wishlist",
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: [{type: "Wishlists", id: "LIST"}],
        }),
        updateWishlist: build.mutation({
            query(data) {
                const {id, ...body} = data;
                return {
                    url: `wishlist/${id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: (result, error, {id}) => [
                {type: "Wishlists", id},
                {type: "Wishlists", id: "LIST"},
            ],
        }),
        deleteWishlist: build.mutation({
            query(id) {
                return {
                    url: `wishlist/${id}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: (result, error, {id}) => [
                {type: "Wishlists", id},
                {type: "Wishlists", id: "LIST"},
            ],
        }),
        updatePassword: build.mutation({
            query(body) {
                return {
                    url: "update-password",
                    method: "POST",
                    body,
                };
            },
        }),
        postOrder: build.mutation({
            query(body) {
                return {
                    url: "order",
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: [{type: "Orders", id: "LIST"}],
        }),
        getOrders: build.query({
            query: () => "order",
            providesTags: (result) =>
                result
                    ? [
                        result.order.map(({id}) => ({type: "Orders", id})),
                        {type: "Orders", id: "LIST"},
                    ]
                    : [{type: "Orders", id: "LIST"}],
        }),
        getOrder: build.query({
            query: (id) => `order/${id}`,
            providesTags: (result, error, id) => [{type: "Orders", id}],
        }),
    }),
});

export const {
    useGetWishlistQuery,
    usePostWishlistMutation,
    useDeleteWishlistMutation,
    useUpdateWishlistMutation,
    useGetWishlistsQuery,
    usePostLoginLumenMutation,
    usePostRegisterLumenMutation,
    useUpdatePasswordMutation,
    usePostForgotPasswordMutation,
    usePostSendNewPasswordMutation,
    usePostReverseLoginMutation,
    usePostAttachSSOMutation,
    usePostOrderConfirmMutation,
    usePostAdminLoginMutation,
    usePostOrderMutation,
    useGetOrderQuery,
    useGetOrdersQuery
} = lumenApi;
