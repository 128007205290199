import React from 'react';
import styles from './cartRowEmpty.module.css';
import { Grid } from '@mui/material';
import SearchProducts from './SearchProducts';

const CartRowEmpty = () => {
    return (
        <Grid item xs={8} className={styles.cartTableRow}>
            <SearchProducts />
        </Grid>
    );
};

export default CartRowEmpty;