import {useEffect} from "react";
import {Link} from "react-router-dom";

const MobileMenuNav = ({getActiveStatus, catalogue}) => {
    useEffect(() => {
        const offCanvasNav = document.querySelector("#offcanvas-mobile-menu__navigation");
        const offCanvasNavSubMenu = offCanvasNav.querySelectorAll(".mobile-sub-menu");
        const anchorLinks = offCanvasNav.querySelectorAll("a");

        for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
            offCanvasNavSubMenu[i].insertAdjacentHTML("beforebegin", "<span class='menu-expand'><i></i></span>");
        }

        const menuExpand = offCanvasNav.querySelectorAll(".menu-expand");
        const numMenuExpand = menuExpand.length;

        for (let i = 0; i < numMenuExpand; i++) {
            menuExpand[i].addEventListener("click", (e) => {
                sideMenuExpand(e);
            });
        }

        for (let i = 0; i < anchorLinks.length; i++) {
            anchorLinks[i].addEventListener("click", () => {
                getActiveStatus(false);
            });
        }
    });

    const sideMenuExpand = (e) => {
        e.currentTarget.parentElement.classList.toggle("active");
    };
    return (<nav
        className="offcanvas-mobile-menu__navigation"
        id="offcanvas-mobile-menu__navigation"
    >
        <ul>
            <li className="menu-item-has-children">
                <Link to="#">
                    SOLUTIONS IMPLANTAIRES
                </Link>
                <ul className="mobile-sub-menu">
                    <li>
                        <Link to="/catalogue/SOLUTIONS%20IMPLANTAIRES/BONE%20LEVEL">
                            Bone Level
                        </Link>
                    </li>
                    <li>
                        <Link to="/catalogue/SOLUTIONS%20IMPLANTAIRES/PERIO%20LEVEL">
                            Perio Level
                        </Link>
                    </li>
                    <li>
                        <Link to="/catalogue/SOLUTIONS%20IMPLANTAIRES/ZIRCONE">
                            Zircone
                        </Link>
                    </li>
                    <li>
                        <Link to="/catalogue/SOLUTIONS%20IMPLANTAIRES/MONOBLOC">
                            Monobloc
                        </Link>
                    </li>
                    <li>
                        <Link to="/catalogue/SOLUTIONS%20IMPLANTAIRES/VIS%20et%20COIFFE%20DE%20CICATRISATION">
                            Vis et coiffe de cicatrisation
                        </Link>
                    </li>
                </ul>
            </li>
            <li className="menu-item-has-children">
                <Link to="#">
                    INSTRUMENTS CHIRURGICAUX
                </Link>
                <ul className="mobile-sub-menu">
                    <li>
                        <Link
                            to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE">
                            Chirurgie classique
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20GUIDEE">
                            Chirurgie guidée
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/GAMME%20PK">
                            Gamme PK
                        </Link>
                    </li>
                    <li>
                        <Link
                            className="bold"
                            to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/FIXATION%20VIS%20OSTEOSYNTHESE"
                        >
                            Fixation vis ostéosynthèse
                        </Link>
                    </li>
                    <li>
                        <Link
                            className="bold"
                            to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/FIXATION%20LAMBEAU"
                        >
                            Fixation lambeau
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/FLAPLESS"
                            className="bold"
                        >
                            Flapless
                        </Link>
                    </li>
                    <li>
                        <Link
                            className="bold"
                            to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/ZIRCONE"
                        >
                            Zircone
                        </Link>
                    </li>
                </ul>
            </li>
            <li className="menu-item-has-children">
                <Link to="#">
                    SOLUTIONS PROTHÉTIQUES
                </Link>
                <ul className="mobile-sub-menu">
                <li>
                        <Link to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N">
                            Kontact / S / S+ / N
                        </Link>
                    </li>
                    <li>
                        <Link to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20PERIO%20LEVEL">
                            Kontact Perio Level
                        </Link>
                    </li>
                    <li>
                        <Link to="/catalogue/SOLUTIONS%20PROTHETIQUES/ZIRCONE">
                            Zircone
                        </Link>
                    </li>
                    <li>
                        <Link to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20MONOBLOC">
                            Monobloc
                        </Link>
                    </li>
                </ul>
            </li>
            <li className="menu-item-has-children">
                <Link to="#">
                    SOLUTIONS GÉNÉRIQUES
                </Link>
                <ul className="mobile-sub-menu">
                    <li>
                        <Link to="/catalogue/SOLUTIONS%20GENERIQUES/SSA-GF">
                            SSA-GF
                        </Link>
                    </li>
                    <li>
                        <Link to="/catalogue/SOLUTIONS%20GENERIQUES/ANTHOGYR">
                            Anthogyr
                        </Link>
                    </li>
                    <li>
                        <Link to="/catalogue/SOLUTIONS%20GENERIQUES/ASTRA">
                            Astra
                        </Link>
                    </li>
                    <li>
                        <Link to="/catalogue/SOLUTIONS%20GENERIQUES/BIOMET">
                            Biomet
                        </Link>
                    </li>
                    <li>
                        <Link to="/catalogue/SOLUTIONS%20GENERIQUES/DENTAURUM">
                            Dentaurum
                        </Link>
                    </li>
                    <li>
                        <Link to="/catalogue/SOLUTIONS%20GENERIQUES/INSTRUMENTS">
                            Instruments
                        </Link>
                    </li>
                    <li>
                        <Link to="/catalogue/SOLUTIONS%20GENERIQUES/NOBEL%20ACTIVE">
                            Nobel Active
                        </Link>
                    </li>
                    <li>
                        <Link to="/catalogue/SOLUTIONS%20GENERIQUES/NOBEL%20BRANEMARK">
                            Nobel Branemark
                        </Link>
                    </li>
                    <li>
                        <Link to="/catalogue/SOLUTIONS%20GENERIQUES/NOBEL%20REPLACE">
                            Nobel Replace
                        </Link>
                    </li>
                    <li>
                        <Link to="/catalogue/SOLUTIONS%20GENERIQUES/STRAUMANN">
                            Straumann
                        </Link>
                    </li>
                    <li>
                        <Link to="/catalogue/SOLUTIONS%20GENERIQUES/TEKKA%20IN%20KONE">
                            Tekka In Kone
                        </Link>
                    </li>
                    <li>
                        <Link to="/catalogue/SOLUTIONS%20GENERIQUES/TEKKA%20PROGRESS">
                            Tekka Progress
                        </Link>
                    </li>
                    <li>
                        <Link to="/catalogue/SOLUTIONS%20GENERIQUES/ZIMMER">
                            Zimmer
                        </Link>
                    </li>
                </ul>
            </li>
            <li className="menu-item-has-children">
                <Link to="#">
                    ÉQUIPEMENTS
                </Link>
                <ul className="mobile-sub-menu">
                    <li>
                        <Link to="/catalogue/EQUIPEMENT/LIGNES%20IRRIGATION">
                            Lignes irrigation
                        </Link>
                    </li>
                    <li>
                        <Link to="/catalogue/EQUIPEMENT/MOTEURS%20DE%20CHIRURGIE">
                            Moteurs de chirurgie
                        </Link>
                    </li>
                    <li>
                        <Link to="/catalogue/EQUIPEMENT/VITAMINE%20D">
                            Vitamine D
                        </Link>
                    </li>
                </ul>
            </li>
            <li className="menu-item-has-children">
                <Link to="#">
                    SOLUTIONS RÉGÉNÉRATRICES
                </Link>
                <ul className="mobile-sub-menu">
                    <li>
                        <Link to="/catalogue/SOLUTIONS%20REGENERATRICES/GREFFES%20OSSEUSES">
                            Greffes osseuses
                        </Link>
                    </li>
                    <li>
                        <Link to="/catalogue/SOLUTIONS%20REGENERATRICES/MEMBRANES%20ET%20MATRICES">
                            Membranes et matrices
                        </Link>
                    </li>
                    <li>
                        <Link to="/catalogue/SOLUTIONS%20REGENERATRICES/DISPOSITIFS%20HEMOSTATIQUES">
                            Dispositifs hémostatiques
                        </Link>
                    </li>
                    <li>
                        <a href="https://pro.biotech-dental.com/professionnel-sante-produits-et-solutions/regeneration/regeneration-osseuse-modelisee/">
                            Régénération osseuse modélisée
                        </a>
                    </li>
                    <li>
                        <a href="https://pro.biotech-dental.com/professionnel-sante-produits-et-solutions/laser/photobiomodulation/">
                            Photobiomodulation
                        </a>
                    </li>
                    <li>
                        <Link to="/catalogue/SOLUTIONS%20REGENERATRICES/ACIDE%20HYALURONIQUE">
                            Acide hyaluronique
                        </Link>
                    </li>
                </ul>
            </li>

            <li className="menu-item-has-children">
                <Link to={`/profile/account`}>
                    MON ESPACE PROFESSIONNEL
                </Link>
                <ul className="mobile-sub-menu">
                    <li>
                    <Link to={`/profile/account`}>
                            Mon profil
                        </Link>
                    </li>
                    <li>
                        <Link to={`/profile/wishlist`}>

                            Mes wishlists
                        </Link>
                    </li>
                    <li>
                        <Link to={`/profile/shipments`}>
                            Mes commandes
                        </Link>
                    </li>
                    <li>
                        <Link to={`/profile/credits`}>
                            Mes crédits
                        </Link>
                    </li>
                    <li>
                        <Link to={`/profile/bills`}>
                            Mes factures
                        </Link>
                    </li>
                    <li>
                        <Link to={`/profile/addresses`}>
                            Mon carnet d'adresses
                        </Link>
                    </li>
                </ul>
            </li>
        </ul>
    </nav>);
};

export default MobileMenuNav;
