import React, {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import styles from "../cartTable.module.css";
import {cartSelector} from "../cartSlice";
import {useSelector} from "react-redux";
import {useHistory} from "react-router";
import SubmitButton from "../../../common/components/buttons/SubmitButton";
import {authSelector} from "../../auth/authSlice";
import CartRowEmpty from "./CartRowEmpty";
import CartRowFull from "./CartRowFull";
import toast from "react-hot-toast";

const LDACartTable = ({products, idsf, company}) => {

    const {push} = useHistory();

    const {isAuth} = useSelector(authSelector);

    const {cartItems} = useSelector(cartSelector);
    const handleSubmitCart = () => {
        if (!cartItems.length) {
            return toast.error('Merci de sélectionner un produit')
        } else {
            for (const item of cartItems) {
                if (!item.lot.length) {
                    return toast.error('Merci de sélectionner un lot pour le produit ' + item.reference)
                } else {
                    if (!isAuth) {
                        push("/login");
                    } else {
                        push("/lab-cart/recap/" + idsf + "/" + company);
                    }
                }
            }
        }
    };

    const rows = [];

    let initNbEmptyRows = products.length > 7 ? 0 : 7 - products.length;

    const [nbEmptyRows, setNbEmptyRows] = useState(initNbEmptyRows);

    const displayEmptyRow = () => {
        for (let index = 0; index < nbEmptyRows; index++) {
            rows.push(<CartRowEmpty key={index}/>);
        }
        return rows;
    };

    useEffect(() => {
        if (nbEmptyRows > 0) {
            setNbEmptyRows(products.length > 7 ? 0 : 7 - products.length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePushLine = () => {
        setNbEmptyRows(nbEmptyRows + 1);
    };

    return (
        <div className={styles.loginContainer}>
            <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="flex-start"
                spacing={3}
            >
                <Grid item xs={10}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-evenly"
                            alignItems="center"
                            className={styles.cartTableHeader}
                        >
                            <Grid item xs={4}>
                                Produit
                            </Grid>
                            <Grid item xs={2}>
                                Lot
                            </Grid>
                            <Grid item xs={1}>
                                Qté
                            </Grid>
                            <Grid item xs={1}>
                                Stock
                            </Grid>
                            <Grid item xs={3}>
                                Com.
                            </Grid>{" "}
                            <Grid item xs={1}>
                                Suppr.
                            </Grid>
                        </Grid>

                        {products.length > 0 &&
                            products.map((product, index) => (
                                <CartRowFull
                                    product={product}
                                    key={index}
                                />
                            ))}

                        {displayEmptyRow()}
                        <div className={styles.bottomButtonsRight}>
                            <SubmitButton
                                buttonText="Ajouter une ligne"
                                onClick={handlePushLine}
                            />
                        </div>
                        <div className={styles.bottomButtons}>
                            <SubmitButton
                                buttonText="Etape suivante"
                                onClick={handleSubmitCart}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <div className={styles.client}>
                        <h3>Client : </h3>
                        <div className={styles.clientInfos}>
                            <p>
                                {idsf} - {company}
                            </p>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default LDACartTable;
