import {Link} from "react-router-dom";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {useRef, useState} from "react";
import useOnClickOutside from "../../../utils/useOnClickOutsideRef";
import mot from "../../../../assets/img/productsImg/1700784-001E.jpg";
import C11 from "../../../../assets/img/productsImg/C11-002.jpg";

const NewCatalogue = () => {

    const [subMenuState, setSubMenuState] = useState([false, false])

    const displaySubmenu = (e, i) => {
        e.preventDefault();
        const clone = subMenuState.slice(0)
        const newState = clone.map((val, index) => {
            if (index === i) {
                return val
            }
            return false
        })

        newState[i] = !newState[i]

        setSubMenuState(newState)
    }

    const hideMenu = () => {
        setSubMenuState([false, false])
    }

    const ref = useRef();

    useOnClickOutside(ref, () => setSubMenuState([false, false]));

    return (
        <nav className="header-content__navigation space-pr--15 space-pl--15 d-none d-lg-block mx-auto" ref={ref}>
            <ul>
                <li>
                    <Link to="#" onClick={(e) => displaySubmenu(e, 0)}>
                        SOLUTIONS IMPLANTAIRES
                        {subMenuState[0] ? <IoIosArrowUp/> : <IoIosArrowDown/>}
                    </Link>

                    <ul className={`sub-menu sub-menu--mega sub-menu--mega--column-5 ${subMenuState[0] ? `d-flex` : 'd-none'}`}
                        onMouseLeave={hideMenu} onClick={hideMenu}>
                        <li className="sub-menu--mega__title">
                            <Link to="/catalogue/SOLUTIONS%20IMPLANTAIRES/BONE%20LEVEL">
                                BONE LEVEL
                            </Link>
                            <ul className="sub-menu--mega__list">
                                <li>
                                    <Link
                                        to="/catalogue/SOLUTIONS%20IMPLANTAIRES/BONE%20LEVEL/Gamme%20Kontact"
                                    >
                                        Gamme Kontact
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20IMPLANTAIRES/BONE%20LEVEL/Gamme%20Kontact/Kontact"
                                            >
                                                Kontact
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20IMPLANTAIRES/BONE%20LEVEL/Gamme%20Kontact/Kontact%20N"
                                            >
                                                Kontact N
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20IMPLANTAIRES/BONE%20LEVEL/Gamme%20Kontact/Kontact%20S"
                                            >
                                                Kontact S
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20IMPLANTAIRES/BONE%20LEVEL/Gamme%20Kontact/Kontact%20S%2B"
                                            >
                                                Kontact S+
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20IMPLANTAIRES/BONE%20LEVEL/Gamme%20Kontact/Kontact%20XL"
                                            >
                                                Kontact XL
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu--mega__title">
                            <Link to="/catalogue/SOLUTIONS%20IMPLANTAIRES/PERIO%20LEVEL">
                                PERIO LEVEL
                            </Link>
                            <ul className="sub-menu--mega__list">
                                <li>
                                    <Link
                                        to="/catalogue/SOLUTIONS%20IMPLANTAIRES/PERIO%20LEVEL/Gamme%20Kontact%20Perio%20Level"
                                    >
                                        Gamme Kontact Perio Level
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20IMPLANTAIRES/PERIO%20LEVEL/Gamme%20Kontact%20Perio%20Level/KONTACT%20PERIO%20LEVEL"
                                            >
                                                Kontact PL
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu--mega__title">
                            <Link to="/catalogue/SOLUTIONS%20IMPLANTAIRES/ZIRCONE">
                                ZIRCONE
                            </Link>
                            <ul className="sub-menu--mega__list">
                                <li>
                                    <Link
                                        to="/catalogue/SOLUTIONS%20IMPLANTAIRES/ZIRCONE/GAMME%20ZIRCONE"
                                    >
                                        Gamme Zircone
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link to="/catalogue/SOLUTIONS%20IMPLANTAIRES/ZIRCONE/GAMME%20ZIRCONE/SB">
                                                SB
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/catalogue/SOLUTIONS%20IMPLANTAIRES/ZIRCONE/GAMME%20ZIRCONE/RB">
                                                RB
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/catalogue/SOLUTIONS%20IMPLANTAIRES/ZIRCONE/GAMME%20ZIRCONE/WB">
                                                WB
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu--mega__title">
                            <Link to="/catalogue/SOLUTIONS%20IMPLANTAIRES/MONOBLOC">
                                MONOBLOC
                            </Link>
                            <ul className="sub-menu--mega__list">
                                <li>
                                    <Link
                                        to="/catalogue/SOLUTIONS%20IMPLANTAIRES/MONOBLOC/Gamme%20Kontact%20Monobloc"
                                    >
                                        Gamme Kontact Monobloc
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu--mega__title">
                            <Link to="/catalogue/SOLUTIONS%20IMPLANTAIRES/VIS%20et%20COIFFE%20DE%20CICATRISATION">
                                VIS et COIFFE DE CICATRISATION
                            </Link>
                            <ul className="sub-menu--mega__list">
                                <li>
                                    <Link
                                        to="/catalogue/SOLUTIONS%20IMPLANTAIRES/VIS%20et%20COIFFE%20DE%20CICATRISATION/Pour%20implants%20Kontact%20%2F%20N%20%2F%20S%20%2F%20S%2B%20%2F%20XL">
                                        Pour implants Kontact / N / S / S+ / XL
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20IMPLANTAIRES/VIS%20et%20COIFFE%20DE%20CICATRISATION/Pour%20implants%20Kontact%20%2F%20N%20%2F%20S%20%2F%20S%2B%20%2F%20XL/VIS%20DE%20COUVERTURE">
                                                Vis de couverture
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20IMPLANTAIRES/VIS%20et%20COIFFE%20DE%20CICATRISATION/Pour%20implants%20Kontact%20%2F%20N%20%2F%20S%20%2F%20S%2B%20%2F%20XL/VIS%20DE%20CICATRISATION">
                                                Vis de cicatrisation
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20IMPLANTAIRES/VIS%20et%20COIFFE%20DE%20CICATRISATION/Pour%20implants%20Kontact%20%2F%20N%20%2F%20S%20%2F%20S%2B%20%2F%20XL/KIT%20DE%20VIS%20DE%20CICATRISATION">
                                                Kit de vis de cicatrisation
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link
                                        to="/catalogue/SOLUTIONS%20IMPLANTAIRES/VIS%20et%20COIFFE%20DE%20CICATRISATION/Pour%20implants%20Kontact%20Perio%20Level%20(KPL)">
                                        Pour implants Kontact Perio Level (KPL)
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20IMPLANTAIRES/VIS%20et%20COIFFE%20DE%20CICATRISATION/Pour%20implants%20Kontact%20Perio%20Level%20(KPL)/VIS%20DE%20COUVERTURE">
                                                Vis de couverture
                                            </Link>
                                        </li>

                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20IMPLANTAIRES/VIS%20et%20COIFFE%20DE%20CICATRISATION/Pour%20implants%20Kontact%20Perio%20Level%20(KPL)/VIS%20DE%20CICATRISATION">
                                                Vis de cicatrisation
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20IMPLANTAIRES/VIS%20et%20COIFFE%20DE%20CICATRISATION/Pour%20implants%20Kontact%20Perio%20Level%20(KPL)/KIT%20DE%20VIS%20DE%20CICATRISATION">
                                                Kit de vis de cicatrisation
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link
                                        to="/catalogue/SOLUTIONS%20IMPLANTAIRES/VIS%20et%20COIFFE%20DE%20CICATRISATION/Pour%20implants%20Zircone">
                                        Pour implants ZIRCONE
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20IMPLANTAIRES/VIS%20et%20COIFFE%20DE%20CICATRISATION/Pour%20implants%20Zircone/VIS%20DE%20COUVERTURE">
                                                Vis de couverture
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20IMPLANTAIRES/VIS%20et%20COIFFE%20DE%20CICATRISATION/Pour%20implants%20Zircone/FACONNEUR%20GINGIVAL">
                                                Façonneur gingival
                                            </Link>
                                        </li>

                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link to="#" onClick={(e) => displaySubmenu(e, 1)}>
                        INSTRUMENTS CHIRURGICAUX {subMenuState[1] ? <IoIosArrowUp/> : <IoIosArrowDown/>}
                    </Link>

                    <ul className={`sub-menu sub-menu--mega sub-menu--mega--column-5 ${subMenuState[1] ? `d-flex` : 'd-none'}`}
                        onMouseLeave={hideMenu} onClick={hideMenu}>
                        <li className="sub-menu--mega__title">
                            <Link
                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE">
                                CHIRURGIE CLASSIQUE
                            </Link>
                            <ul className="sub-menu--mega__list">
                                <li>
                                    <Link
                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE/TROUSSES%20DE%20CHIRURGIE"
                                    >
                                        Trousses de chirurgie
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE/TROUSSES%20DE%20CHIRURGIE/TROUSSE%20PLEINE"
                                            >
                                                Trousse pleine
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE/TROUSSES%20DE%20CHIRURGIE/TROUSSE%20VIDE"
                                            >
                                                Trousse vide
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link
                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE/BUTEES%20CHIRURGICALES">
                                        Butées chirurgicales
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE/BUTEES%20CHIRURGICALES/BUTEES%20UNITAIRES"
                                            >
                                                Butées unitaires
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE/BUTEES%20CHIRURGICALES/KIT%20DE%20BUTEES"
                                            >
                                                Kit de butées
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link
                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE/INSTRUMENTS">
                                        Instruments
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE/INSTRUMENTS/FORETS%20DE%20MARQUAGE"
                                            >
                                                Forets de marquage
                                            </Link>
                                        </li>


                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE/INSTRUMENTS/FORETS%20PILOTES"
                                            >
                                                Forets pilotes
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE/INSTRUMENTS/FORETS%20ETAGES"
                                            >
                                                Forets étagés
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE/INSTRUMENTS/FORETS%20CORTICAUX%20POUR%20VIS%20DE%20CICATRISATION"
                                            >
                                                Forets corticaux pour vis de cicatrisation
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE/INSTRUMENTS/ALESOIRS"
                                            >
                                                Alésoirs
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE/INSTRUMENTS/COUNTERSINK%20POUR%20IMPLANTS%20KONTACT%20PERIO%20LEVEL"
                                            >
                                                Countersink pour implants Kontact Perio Level
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE/INSTRUMENTS/FRAISES%20CORTICALES%20POUR%20IMPLANTS%20KONTACT%20S%2B"
                                            >
                                                Fraises corticales pour implants Kontact S+
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE/INSTRUMENTS/JAUGES"
                                            >
                                                Jauges
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE/INSTRUMENTS/MANDRINS"
                                            >
                                                Mandrins
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE/INSTRUMENTS/PROLONGATEUR%20DE%20FORET"
                                            >
                                                Prolongateur de foret
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE/INSTRUMENTS/TOURNEVIS"
                                            >
                                                Tournevis
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE/INSTRUMENTS/ADAPTATEURS"
                                            >
                                                Adaptateurs
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE/INSTRUMENTS/ESPACEUR"
                                            >
                                                Espaceur
                                            </Link>
                                        </li>

                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE/INSTRUMENTS/EXTRACTEURS%20DE%20PILIERS"
                                            >
                                                Extracteurs de piliers
                                            </Link>
                                        </li>


                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE/INSTRUMENTS/CLES"
                                            >
                                                Clés
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE/INSTRUMENTS/GUIDES%20DE%20FORAGE"
                                            >
                                                Guides de forage
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link
                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20CLASSIQUE/TREPANS%20POUR%20EXTRACTION%20D%27IMPLANT">
                                        Trépans pour extraction d'implant
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu--mega__title">
                            <ul className="sub-menu--mega__list">
                                <li className="bold mb-5">
                                    <Link
                                        className="bold"
                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20GUIDEE">
                                        CHIRURGIE GUIDÉE
                                    </Link>
                                    <ul className="sub-menu--mega__list">
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20GUIDEE/FULL%20GUIDED"
                                            >
                                                Full guided
                                            </Link>
                                            <ul className="sub-menu--mega__list__second-level">
                                                <li>
                                                    <Link
                                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20GUIDEE/FULL%20GUIDED/INSTRUMENTS"
                                                    >
                                                        Instruments
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20GUIDEE/FULL%20GUIDED/MASTERS%20TUBES"
                                                    >
                                                        Masters tube
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20GUIDEE/FULL%20GUIDED/TROUSSE%20DE%20CHIRURGIE%20GUIDEE"
                                                    >
                                                        Trousse de chirurgie guidée
                                                    </Link>
                                                </li>

                                            </ul>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20GUIDEE/PILOTE"
                                            >
                                                Pilote
                                            </Link>
                                            <ul className="sub-menu--mega__list__second-level">
                                                <li>
                                                    <Link
                                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20GUIDEE/PILOTE/FORETS%20PILOTES"
                                                    >
                                                        Forets pilotes
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20GUIDEE/PILOTE/KIT%20FORETS%20PILOTES"
                                                    >
                                                        Kit Forets pilotes
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/CHIRURGIE%20GUIDEE/PILOTE/MASTERS%20TUBES"
                                                    >
                                                        Masters tubes
                                                    </Link>
                                                </li>

                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li className="bold mb-5">
                                    <Link
                                        className="bold"
                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/GAMME%20PK"
                                    >
                                        GAMME PK
                                    </Link>
                                    <ul className="sub-menu--mega__list">
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/GAMME%20PK/VIS%20SCREWPINSKIT"
                                            >
                                                Vis Screwpinskit
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/GAMME%20PK/KIT%20SCREWPINSKIT"
                                            >
                                                Kit Screwpinskit
                                            </Link>
                                        </li>


                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/GAMME%20PK/DISPOSITIF%20DE%20PRELEVEMENT%20D%27OS%20AUTOGENE"
                                            >
                                                Dispositif De Prélèvement D'os Autogène
                                            </Link>
                                        </li>

                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu--mega__title">
                            <ul className="sub-menu--mega__list">
                                <li className="bold mb-5">
                                    <Link
                                        className="bold"
                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/FIXATION%20VIS%20OSTEOSYNTHESE"
                                    >
                                        FIXATION VIS OSTÉOSYNTHÈSE
                                    </Link>
                                    <ul className="sub-menu--mega__list">
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/FIXATION%20VIS%20OSTEOSYNTHESE/INSTRUMENTS"
                                            >
                                                Instruments
                                            </Link>
                                            <ul className="sub-menu--mega__list__second-level">
                                                <li>
                                                    <Link
                                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/FIXATION%20VIS%20OSTEOSYNTHESE/INSTRUMENTS/FORETS"
                                                    >
                                                        Forets
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/FIXATION%20VIS%20OSTEOSYNTHESE/INSTRUMENTS/AXES%20DE%20TOURNEVIS"
                                                    >
                                                        Axes de tournevis
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/FIXATION%20VIS%20OSTEOSYNTHESE/INSTRUMENTS/MANCHE"
                                                    >
                                                        Manche
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/FIXATION%20VIS%20OSTEOSYNTHESE/VIS%20FIX%20IN"
                                            >
                                                Vis Fix In
                                            </Link>
                                        </li>


                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/FIXATION%20VIS%20OSTEOSYNTHESE/KIT%20FIX%20IN"
                                            >
                                                Kit Fix In
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/FIXATION%20VIS%20OSTEOSYNTHESE/VIS%20SCREWPINSKIT"
                                            >
                                                Vis Screwpinskit
                                            </Link>
                                        </li>

                                    </ul>
                                </li>
                                <li className="bold mb-5">
                                    <Link
                                        className="bold"
                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/FIXATION%20LAMBEAU"
                                    >
                                        FIXATION LAMBEAU
                                    </Link>
                                    <ul className="sub-menu--mega__list">
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/FIXATION%20LAMBEAU/INSTRUMENTS"
                                            >
                                                Instruments
                                            </Link>
                                            <ul className="sub-menu--mega__list__second-level">
                                                <li>
                                                    <Link
                                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/FIXATION%20LAMBEAU/INSTRUMENTS/MAILLET"
                                                    >
                                                        Maillet
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/FIXATION%20LAMBEAU/INSTRUMENTS/IMPACTEUR"
                                                    >
                                                        Impacteur
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/FIXATION%20LAMBEAU/CLOU"
                                            >
                                                Clou
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/FIXATION%20LAMBEAU/KIT%20CFL"
                                            >
                                                Kit CFL
                                            </Link>
                                            <ul className="sub-menu--mega__list__second-level">
                                                <li>
                                                    <Link
                                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/FIXATION%20LAMBEAU/KIT%20CFL/PLEIN"
                                                    >
                                                        Plein
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/FIXATION%20LAMBEAU/KIT%20CFL/VIDE"
                                                    >
                                                        Vide
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu--mega__title">
                            <ul className="sub-menu--mega__list">
                                <li className="bold mb-5">
                                    <Link
                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/FLAPLESS"
                                        className="bold"
                                    >
                                        FLAPLESS
                                    </Link>
                                    <ul className="sub-menu--mega__list">
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/FLAPLESS/KIT"
                                            >
                                                Kit
                                            </Link>
                                            <ul className="sub-menu--mega__list__second-level">
                                                <li>
                                                    <Link
                                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/FLAPLESS/KIT/PLEIN"
                                                    >
                                                        Plein
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/FLAPLESS/KIT/VIDE"
                                                    >
                                                        Vide
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/FLAPLESS/TREPANS%20FLAPLESS"
                                            >
                                                Trépans Flapless
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu--mega__title">

                            <Link
                                className="bold"
                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/ZIRCONE"
                            >
                                ZIRCONE
                            </Link>
                            <ul className="sub-menu--mega__list">
                                <li>
                                    <Link
                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/ZIRCONE/INSTRUMENTS"
                                    >
                                        Instruments
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/ZIRCONE/INSTRUMENTS/CLE"
                                            >
                                                Clé
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/ZIRCONE/INSTRUMENTS/FRAISE%20BOULE"
                                            >
                                                Fraise boule
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/ZIRCONE/INSTRUMENTS/JAUGE%20DE%20PROFONDEUR"
                                            >
                                                Jauge de profondeur
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/ZIRCONE/INSTRUMENTS/KIT%20DE%20BUTEES"
                                            >
                                                Kit de butées
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/ZIRCONE/INSTRUMENTS/PICKUP"
                                            >
                                                Pickup
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/ZIRCONE/INSTRUMENTS/PROFIL%20DRILL"
                                            >
                                                Profil Drill
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/ZIRCONE/INSTRUMENTS/ZERADRILL"
                                            >
                                                Zeradrill
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/ZIRCONE/INSTRUMENTS/ZERATAPE"
                                            >
                                                Zeratape
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link
                                        to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/ZIRCONE/KIT"
                                    >
                                        Kit
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/ZIRCONE/KIT/PLATEAU%20CHIRURGICAL"
                                            >
                                                Plateau chirurgical
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/INSTRUMENTS%20CHIRURGICAUX/ZIRCONE/KIT/SET%20COMPLET"
                                            >
                                                Set Complet
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link to="#" onClick={(e) => displaySubmenu(e, 3)}>
                        SOLUTIONS PROTHÉTIQUES {subMenuState[3] ? <IoIosArrowUp/> : <IoIosArrowDown/>}
                    </Link>

                    <ul className={`sub-menu sub-menu--mega sub-menu--mega--column-4 ${subMenuState[3] ? `d-flex` : 'd-none'}`}
                        onMouseLeave={hideMenu} onClick={hideMenu}>
                        <li className="sub-menu--mega__title">
                            <Link to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N">
                                KONTACT / S / S+ / N
                            </Link>
                            <ul className="sub-menu--mega__list">
                                <li>
                                    <Link
                                        to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/PILIERS"
                                    >
                                        Piliers
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/PILIERS/DROITS"
                                            >
                                                Droits
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/PILIERS/ANGULES"
                                            >
                                                Angulés
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/PILIERS/CONIQUES"
                                            >
                                                Coniques
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/PILIERS/%20CAD%20%2F%20CAM%20TI-BASE"
                                            >
                                                CAD / CAM TI-BASE
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/PILIERS/PROVISOIRES"
                                            >
                                                Provisoires
                                            </Link>
                                        </li>

                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/PILIERS/SURCOULABLES"
                                            >
                                                Surcoulables
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/PILIERS/FIT-POST"
                                            >
                                                FitPost
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/PILIERS/UNI-POST"
                                            >
                                                UniPost
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/PILIERS/OMNI-POST"
                                            >
                                                OmniPost
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/PILIERS/NANO-POST"
                                            >
                                                NanoPost
                                            </Link>
                                        </li>

                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/PILIERS/ISO-POST"
                                            >
                                                IsoPost
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/PILIERS/ATTACHEMENTS"
                                            >
                                                Attachements
                                            </Link>
                                        </li>

                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/PILIERS/PILIERS%20D%27ESSAI"
                                            >
                                                Piliers d'essai
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link
                                        to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/PILIERS%20ET%20COIFFES%20OMNI-POST%20%2F%20SSA"
                                    >
                                        Piliers et Coiffes OmniPost / SSA
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/PILIERS%20ET%20COIFFES%20OMNI-POST%20%2F%20SSA/PILIERS%20OMNI-POST"
                                            >
                                                Piliers OmniPost
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/PILIERS%20ET%20COIFFES%20OMNI-POST%20%2F%20SSA/PILIERS%20SSA%20DIRECT%20IMPLANT"
                                            >
                                                Piliers SSA Direct Implant
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/PILIERS%20ET%20COIFFES%20OMNI-POST%20%2F%20SSA/COIFFES%20SSA-GF%20%28SUR%20OMNI-POST%29"
                                            >
                                                Coiffes SSA-GF (sur OmniPost)
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/PILIERS%20ET%20COIFFES%20OMNI-POST%20%2F%20SSA/COIFFES%20OMNI-POST"
                                            >
                                                Coiffes OmniPost
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link
                                        to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/ACCASTILLAGE%20PROTHETIQUE">
                                        Accastillage prothétique
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/ACCASTILLAGE%20PROTHETIQUE/COIFFES%20PROTHETIQUES"
                                            >
                                                Coiffes prothétiques
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/ACCASTILLAGE%20PROTHETIQUE/COIFFES%20DE%20CICATRISATION"
                                            >
                                                Coiffes de cicatrisation
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/ACCASTILLAGE%20PROTHETIQUE/COIFFES%20DE%20PARALLELISME"
                                            >
                                                Coiffes de parallélisme
                                            </Link>
                                        </li>

                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/ACCASTILLAGE%20PROTHETIQUE/GAINES"
                                            >
                                                Gaines
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/ACCASTILLAGE%20PROTHETIQUE/JAUGES"
                                            >
                                                Jauges
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/ACCASTILLAGE%20PROTHETIQUE/VIS%20%28DE%20PILIER%2C%20POUR%20VISSAGE%20ANGULE%29"
                                            >
                                                Vis (de pilier , pour vissage angulé)
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/ACCASTILLAGE%20PROTHETIQUE/ATTACHEMENTS"
                                            >
                                                Attachements
                                            </Link>
                                        </li>
                                    </ul>
                                    <li>
                                        <Link
                                            to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/INSTRUMENTS"
                                        >
                                            Instruments
                                        </Link>
                                        <ul className="sub-menu--mega__list__second-level">
                                            <li>
                                                <Link
                                                    to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/INSTRUMENTS/ADAPTATEURS"
                                                >
                                                    Adaptateurs
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/INSTRUMENTS/CLES"
                                                >
                                                    Clés
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/INSTRUMENTS/EXTRACTEURS%20DE%20PILIERS"
                                                >
                                                    Extracteurs de piliers
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/INSTRUMENTS/TOURNEVIS"

                                                >
                                                    Tournevis
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/INSTRUMENTS/MANDRINS%20POUR%20PILIER%20CONIQUE"

                                                >
                                                    Mandrins pour pilier conique
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/INSTRUMENTS/TROUSSE%20DE%20PROTHESE"

                                                >
                                                    Trousse de prothèse
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/INSTRUMENTS/KIT%20PILIERS%20CONIQUES"
                                                >
                                                    Kit piliers coniques
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>

                                </li>
                                <li>
                                    <Link
                                        to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/PRISE%20D%27EMPREINTES"
                                    >
                                        Prise d'empreintes
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/PRISE%20D%27EMPREINTES/DUPLICATAS"
                                            >
                                                Duplicatas
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/PRISE%20D%27EMPREINTES/DUPLICATAS%20ET%20ANALOGUES%20NUMERIQUES"
                                            >
                                                Duplicatas et analogues numériques
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/PRISE%20D%27EMPREINTES/SCAN%20BODY"
                                            >
                                                Scan Body
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20%2F%20S%20%2F%20S%2B%20%2F%20N/PRISE%20D%27EMPREINTES/TRANSFERTS%20ET%20VIS%20DE%20TRANSFERT"
                                            >
                                                Transferts et vis de transfert
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu--mega__title">
                            <Link to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20PERIO%20LEVEL">
                                KONTACT PERIO LEVEL
                            </Link>
                            <ul className="sub-menu--mega__list">
                                <li>
                                    <Link
                                        to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20PERIO%20LEVEL/PILIERS"
                                    >
                                        Piliers
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20PERIO%20LEVEL/PILIERS/DROITS"
                                            >
                                                Droits
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20PERIO%20LEVEL/PILIERS/ANGULES"
                                            >
                                                Angulés
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20PERIO%20LEVEL/PILIERS/CONIQUES"
                                            >
                                                Coniques
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20PERIO%20LEVEL/PILIERS/%20CAD%20%2F%20CAM%20TI-BASE"
                                            >
                                                CAD / CAM TI-BASE
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20PERIO%20LEVEL/PILIERS/PROVISOIRES"
                                            >
                                                Provisoires
                                            </Link>
                                        </li>

                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20PERIO%20LEVEL/PILIERS/ATTACHEMENTS"
                                            >
                                                Attachements
                                            </Link>
                                        </li>

                                    </ul>
                                </li>
                                <li>
                                    <Link
                                        to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20PERIO%20LEVEL/PILIERS%20SSA"
                                    >
                                        Piliers SSA
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20PERIO%20LEVEL/PILIERS%20SSA/SSA%20DIRECT%20IMPLANT"
                                            >
                                                SSA Direct Implant
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link
                                        to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20PERIO%20LEVEL/INSTRUMENTS"
                                    >
                                        Instruments
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20PERIO%20LEVEL/INSTRUMENTS/MANDRINS"
                                            >
                                                Mandrins
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link
                                        to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20PERIO%20LEVEL/ACCASTILLAGE%20PROTHETIQUE">
                                        Accastillage prothétique
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20PERIO%20LEVEL/ACCASTILLAGE%20PROTHETIQUE/COIFFES%20DE%20CICATRISATION%20POUR%20PILIERS%20CONIQUES"
                                            >
                                                Coiffes de cicatrisation pour piliers coniques
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20PERIO%20LEVEL/ACCASTILLAGE%20PROTHETIQUE/GAINES"
                                            >
                                                Gaines
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20PERIO%20LEVEL/ACCASTILLAGE%20PROTHETIQUE/VIS%20%28DE%20PILIER%2C%20POUR%20VISSAGE%20ANGULE%29"
                                            >
                                                Vis (de pilier , pour vissage angulé)
                                            </Link>
                                        </li>
                                    </ul>
                                    <li>
                                        <Link
                                            to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20PERIO%20LEVEL/PRISE%20D%27EMPREINTES"
                                        >
                                            Prise d'empreintes
                                        </Link>
                                        <ul className="sub-menu--mega__list__second-level">
                                            <li>
                                                <Link
                                                    to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20PERIO%20LEVEL/PRISE%20D%27EMPREINTES/DUPLICATAS"
                                                >
                                                    Duplicatas
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20PERIO%20LEVEL/PRISE%20D%27EMPREINTES/SCAN%20BODY"
                                                >
                                                    Scan Body
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20PERIO%20LEVEL/PRISE%20D%27EMPREINTES/TRANSFERTS%20ET%20VIS%20DE%20TRANSFERT"
                                                >
                                                    Transferts et vis de transfert
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu--mega__title">
                            <Link to="/catalogue/SOLUTIONS%20PROTHETIQUES/ZIRCONE">
                                ZIRCONE
                            </Link>
                            <ul className="sub-menu--mega__list">
                                <li>
                                    <Link
                                        to="/catalogue/SOLUTIONS%20PROTHETIQUES/ZIRCONE/PILIERS"
                                    >
                                        Piliers
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/ZIRCONE/PILIERS/CLASSIQUE"
                                            >
                                                Classique
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/ZIRCONE/PILIERS/SYSTEME%20LOCATOR"
                                            >
                                                Système Locator
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/ZIRCONE/PILIERS/ZERABASE"
                                            >
                                                Zerabase
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/ZIRCONE/PILIERS/PILIERS%20PROVISOIRES"
                                            >
                                                Piliers provisoires
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/catalogue/SOLUTIONS%20PROTHETIQUES/ZIRCONE/ACCASTILLAGE%20PROTHETIQUE">
                                        Accastillage prothétique
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/ZIRCONE/ACCASTILLAGE%20PROTHETIQUE/VIS%20%28DE%20PILIER%2C%20POUR%20VISSAGE%20ANGULE%29">

                                                Vis (de pilier, pour vissage angulé)
                                            </Link>
                                        </li>
                                    </ul>
                                    <li>
                                        <Link
                                            to="/catalogue/SOLUTIONS%20PROTHETIQUES/ZIRCONE/KIT"
                                        >
                                            Kit
                                        </Link>
                                        <ul className="sub-menu--mega__list__second-level">
                                            <li>
                                                <Link
                                                    to="/catalogue/SOLUTIONS%20PROTHETIQUES/ZIRCONE/KIT/PLATEAU%20PROTHESE"
                                                >
                                                    Plateau prothèse
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/catalogue/SOLUTIONS%20PROTHETIQUES/ZIRCONE/KIT/SET%20COMPLET"
                                                >
                                                    Set Complet
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link
                                            to="/catalogue/SOLUTIONS%20PROTHETIQUES/ZIRCONE/PRISE%20D%27EMPREINTES"
                                        >
                                            Prise d'empreintes
                                        </Link>
                                        <ul className="sub-menu--mega__list__second-level">
                                            <li>
                                                <Link
                                                    to="/catalogue/SOLUTIONS%20PROTHETIQUES/ZIRCONE/PRISE%20D%27EMPREINTES/DUPLICATAS"
                                                >
                                                    Duplicatas
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/catalogue/SOLUTIONS%20PROTHETIQUES/ZIRCONE/PRISE%20D%27EMPREINTES/SCAN%20BODY"
                                                >
                                                    Scan Body
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/catalogue/SOLUTIONS%20PROTHETIQUES/ZIRCONE/PRISE%20D%27EMPREINTES/TRANSFERTS%20ET%20VIS%20DE%20TRANSFERT"
                                                >
                                                    Transferts et vis de transfert
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link
                                            to="/catalogue/SOLUTIONS%20PROTHETIQUES/ZIRCONE/CLE"
                                        >
                                            Clé
                                        </Link>
                                    </li>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu--mega__title">
                            <Link to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20MONOBLOC">
                                KONTACT MONOBLOC
                            </Link>
                            <ul className="sub-menu--mega__list">
                                <li>
                                    <Link
                                        to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20MONOBLOC/ACCASTILLAGE%20PROTHETIQUE">
                                        Accastillage prothétique
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20MONOBLOC/ACCASTILLAGE%20PROTHETIQUE/GAINES">

                                                Gaines
                                            </Link>
                                        </li>
                                    </ul>
                                    <li>
                                        <Link
                                            to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20MONOBLOC/INSTRUMENTS"
                                        >
                                            Instruments
                                        </Link>
                                        <ul className="sub-menu--mega__list__second-level">
                                            <li>
                                                <Link
                                                    to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20MONOBLOC/INSTRUMENTS/MANDRINS%20POUR%20PILIER%20CONIQUE"
                                                >
                                                    Mandrins pour pilier conique
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link
                                            to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20MONOBLOC/PRISE%20D%27EMPREINTES"
                                        >
                                            Prise d'empreintes
                                        </Link>
                                        <ul className="sub-menu--mega__list__second-level">
                                            <li>
                                                <Link
                                                    to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20MONOBLOC/PRISE%20D%27EMPREINTES/DUPLICATAS">
                                                    Duplicatas
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/catalogue/SOLUTIONS%20PROTHETIQUES/KONTACT%20MONOBLOC/PRISE%20D%27EMPREINTES/TRANSFERTS%20ET%20VIS%20DE%20TRANSFERT/">
                                                    Transferts et vis de transfert
                                                </Link>
                                            </li>
                                        </ul>

                                    </li>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link to="#" onClick={(e) => displaySubmenu(e, 4)}>
                        SOLUTIONS GÉNÉRIQUES {subMenuState[4] ? <IoIosArrowUp/> : <IoIosArrowDown/>}
                    </Link>

                    <ul className={`sub-menu sub-menu--mega sub-menu--mega--column-2 ${subMenuState[4] ? `d-flex` : 'd-none'}`}
                        onMouseLeave={hideMenu} onClick={hideMenu}>
                        <li className="sub-menu--mega__title">


                            <Link to="/catalogue/SOLUTIONS%20GENERIQUES/SSA-GF">
                                SSA-GF
                            </Link>
                            <ul className="sub-menu--mega__list">
                                <li>
                                    <Link to="/catalogue/SOLUTIONS%20GENERIQUES/SSA-GF/SUR%20NOBEL">
                                        Sur Nobel
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/catalogue/SOLUTIONS%20GENERIQUES/SSA-GF/SUR%20STRAUMANN">
                                        Sur Straumann
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link to="/catalogue/SOLUTIONS%20GENERIQUES/SSA-GF/SUR%20STRAUMANN/RC">
                                                RC
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/catalogue/SOLUTIONS%20GENERIQUES/SSA-GF/SUR%20STRAUMANN/RP">
                                                RP
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/catalogue/SOLUTIONS%20GENERIQUES/SSA-GF/SUR%20STRAUMANN/WP">
                                                WP
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul className="sub-menu--mega__columns2">
                                <li>
                                    <Link to="/catalogue/SOLUTIONS%20GENERIQUES/ANTHOGYR">
                                        ANTHOGYR
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/catalogue/SOLUTIONS%20GENERIQUES/ASTRA">
                                        ASTRA
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/catalogue/SOLUTIONS%20GENERIQUES/BIOMET">
                                        BIOMET
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/catalogue/SOLUTIONS%20GENERIQUES/DENTAURUM">
                                        DENTAURUM
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/catalogue/SOLUTIONS%20GENERIQUES/INSTRUMENTS">
                                        INSTRUMENTS
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/catalogue/SOLUTIONS%20GENERIQUES/NOBEL%20ACTIVE">
                                        NOBEL ACTIVE
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/catalogue/SOLUTIONS%20GENERIQUES/NOBEL%20BRANEMARK">
                                        NOBEL BRANEMARK
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/catalogue/SOLUTIONS%20GENERIQUES/NOBEL%20REPLACE">
                                        NOBEL REPLACE
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/catalogue/SOLUTIONS%20GENERIQUES/STRAUMANN">
                                        STRAUMANN
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/catalogue/SOLUTIONS%20GENERIQUES/TEKKA%20IN%20KONE">
                                        TEKKA IN KONE
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/catalogue/SOLUTIONS%20GENERIQUES/TEKKA%20PROGRESS">
                                        TEKKA PROGRESS
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/catalogue/SOLUTIONS%20GENERIQUES/ZIMMER">
                                        ZIMMER
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link to="#" onClick={(e) => displaySubmenu(e, 5)}>
                        ÉQUIPEMENTS {subMenuState[5] ? <IoIosArrowUp/> : <IoIosArrowDown/>}
                    </Link>

                    <ul className={`sub-menu sub-menu--mega sub-menu--mega--column-4 ${subMenuState[5] ? `d-flex` : 'd-none'}`}
                        onMouseLeave={hideMenu} onClick={hideMenu}>
                        <li>
                            <img
                                src={
                                    mot
                                }
                                className="img-fluid"
                                alt=""
                            />
                        </li>
                        <li className="sub-menu--mega__title">
                            <Link to="/catalogue/EQUIPEMENT/LIGNES%20IRRIGATION">
                                LIGNES IRRIGATIONS
                            </Link>
                            <ul className="sub-menu--mega__list">
                                <li>
                                    <Link to="/catalogue/EQUIPEMENT/LIGNES%20IRRIGATION/PRODUITS%20ADAPTABLES">
                                        Produits adaptables
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/catalogue/EQUIPEMENT/LIGNES%20IRRIGATION/PRODUITS%20ORIGINAUX"
                                    >
                                        Produits originaux
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu--mega__title">
                            <Link to="/catalogue/EQUIPEMENT/MOTEURS%20DE%20CHIRURGIE">
                                MOTEURS DE CHIRURGIE
                            </Link>
                            <ul className="sub-menu--mega__list">
                                <li>
                                    <Link to="/catalogue/EQUIPEMENT/MOTEURS%20DE%20CHIRURGIE/BIOPOWER">
                                        Biopower
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/catalogue/EQUIPEMENT/MOTEURS%20DE%20CHIRURGIE/PIEZO%20CHIRURGIE"
                                    >
                                        Piezo chirurgie
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu--mega__title">
                            <Link to="/catalogue/EQUIPEMENT/VITAMINE%20D">
                                VITAMINE D
                            </Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link to="#" onClick={(e) => displaySubmenu(e, 6)}>
                        SOLUTIONS RÉGÉNÉRATRICES {subMenuState[6] ? <IoIosArrowUp/> : <IoIosArrowDown/>}
                    </Link>

                    <ul className={`sub-menu sub-menu--mega sub-menu--mega--column-6 ${subMenuState[6] ? `d-flex` : 'd-none'}`}
                        onMouseLeave={hideMenu} onClick={hideMenu}>
                        <li className="sub-menu--mega__title">
                            <Link to="/catalogue/SOLUTIONS%20REGENERATRICES/GREFFES%20OSSEUSES">
                                GREFFES OSSEUSES
                            </Link>
                            <ul className="sub-menu--mega__list">
                                <li>
                                    <Link
                                        to="/catalogue/SOLUTIONS%20REGENERATRICES/GREFFES%20OSSEUSES/XENOGREFFES">
                                        Xénogreffes
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/GREFFES%20OSSEUSES/XENOGREFFES/COLLAPAT">
                                                Collapat
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/GREFFES%20OSSEUSES/XENOGREFFES/CERABONE">
                                                Cerabone
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/GREFFES%20OSSEUSES/XENOGREFFES/CERABONE%2B">
                                                Cerabone +
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/GREFFES%20OSSEUSES/XENOGREFFES/MINEROSS%20X">
                                                Mineross X
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/GREFFES%20OSSEUSES/XENOGREFFES/MINEROSS%20XP">
                                                Mineross XP
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/catalogue/SOLUTIONS%20REGENERATRICES/GREFFES%20OSSEUSES/SYNTHETIQUES">
                                        Synthétiques
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/GREFFES%20OSSEUSES/SYNTHETIQUES/GUIDOR%20EASY-GRAFT%20CLASSIC%20%2B">
                                                Guidor Easy-Graft Classic +
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/GREFFES%20OSSEUSES/SYNTHETIQUES/GUIDOR%20EASY-GRAFT%20CRYSTAL">
                                                Guidor Easy-Graft Crystal
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/GREFFES%20OSSEUSES/SYNTHETIQUES/GUIDOR%20EASY-GRAFT%20CRYSTAL%20%2B">
                                                Guidor Easy-Graft Crystal +
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/GREFFES%20OSSEUSES/SYNTHETIQUES/MAXRESORB">
                                                Maxresorb
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/GREFFES%20OSSEUSES/SYNTHETIQUES/MAXRESORB%20INJECT">
                                                Maxresorb inject
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu--mega__title">
                            <Link to="/catalogue/SOLUTIONS%20REGENERATRICES/MEMBRANES%20ET%20MATRICES">
                                MEMBRANES ET MATRICES
                            </Link>
                            <ul className="sub-menu--mega__list">
                                <li>
                                    <Link
                                        to="/catalogue/SOLUTIONS%20REGENERATRICES/MEMBRANES%20ET%20MATRICES/XENOGREFFES">
                                        Xénogreffes
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/MEMBRANES%20ET%20MATRICES/XENOGREFFES/NEA%20COVA">
                                                Nea Cova
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/MEMBRANES%20ET%20MATRICES/XENOGREFFES/JASON">
                                                Jason
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/MEMBRANES%20ET%20MATRICES/XENOGREFFES/COLLPROTECT">
                                                Collprotect
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link
                                        to="/catalogue/SOLUTIONS%20REGENERATRICES/MEMBRANES%20ET%20MATRICES/SYNTHETIQUES">
                                        Synthétiques
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/MEMBRANES%20ET%20MATRICES/SYNTHETIQUES/PERMAMEN">
                                                Permamen
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link
                                        to="/catalogue/SOLUTIONS%20REGENERATRICES/MEMBRANES%20ET%20MATRICES/GAMME%20MAGNESIUM">
                                        Gamme Magnésium
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/MEMBRANES%20ET%20MATRICES/GAMME%20MAGNESIUM/NOVAMAG">
                                                Novamag
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/MEMBRANES%20ET%20MATRICES/GAMME%20MAGNESIUM/VIS">
                                                Vis
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/MEMBRANES%20ET%20MATRICES/GAMME%20MAGNESIUM/INSTRUMENTS">
                                                Instruments
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link
                                        to="/catalogue/SOLUTIONS%20REGENERATRICES/MEMBRANES%20ET%20MATRICES/MATRICES%20DE%20RECONSTRUCTION%20TISSULAIRE">
                                        Matrices De Reconstruction Tissulaire
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/MEMBRANES%20ET%20MATRICES/MATRICES%20DE%20RECONSTRUCTION%20TISSULAIRE/MUCODERM">
                                                Mucoderm
                                            </Link>
                                        </li>
                                    </ul>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/MEMBRANES%20ET%20MATRICES/MATRICES%20DE%20RECONSTRUCTION%20TISSULAIRE/NOVOMATRIX">
                                                Novomatrix
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu--mega__title">
                            <Link
                                to="/catalogue/SOLUTIONS%20REGENERATRICES/DISPOSITIFS%20HEMOSTATIQUES">
                                DISPOSITIFS HÉMOSTATIQUES
                            </Link>
                            <ul className="sub-menu--mega__list">
                                <li>
                                    <Link
                                        to="/catalogue/SOLUTIONS%20REGENERATRICES/DISPOSITIFS%20HEMOSTATIQUES/CONES">
                                        Cones
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/DISPOSITIFS%20HEMOSTATIQUES/CONES/COLLACONE">
                                                Collacone
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link
                                        to="/catalogue/SOLUTIONS%20REGENERATRICES/DISPOSITIFS%20HEMOSTATIQUES/EPONGES">
                                        Eponges
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/DISPOSITIFS%20HEMOSTATIQUES/EPONGES/COLLAFLEECE">
                                                Collafleece
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu--mega__title">
                            <a href="https://pro.biotech-dental.com/professionnel-sante-produits-et-solutions/regeneration/regeneration-osseuse-modelisee/"
                               target="_blank">
                                RÉGÉNÉRATION OSSEUSE MODÉLISÉE
                            </a>
                            <ul className="sub-menu--mega__list">
                                <li>
                                    <a href="https://pro.biotech-dental.com/professionnel-sante-produits-et-solutions/regeneration/regeneration-osseuse-modelisee/"
                                       target="_blank">
                                        Your3DCage
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu--mega__title">
                            <a href="https://pro.biotech-dental.com/professionnel-sante-produits-et-solutions/laser/photobiomodulation/"
                               target="_blank">
                                PHOTOBIOMODULATION
                            </a>
                            <ul className="sub-menu--mega__list">
                                <li>
                                    <a href="https://pro.biotech-dental.com/professionnel-sante-produits-et-solutions/laser/photobiomodulation/"
                                       target="_blank">
                                        ATP38
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu--mega__title">
                            <Link to="/catalogue/SOLUTIONS%20REGENERATRICES/ACIDE%20HYALURONIQUE">
                                ACIDE HYALURONIQUE
                            </Link>
                            <ul className="sub-menu--mega__list">
                                <li>
                                    <Link to="/catalogue/SOLUTIONS%20REGENERATRICES/ACIDE%20HYALURONIQUE/FILLERS">
                                        Fillers
                                    </Link>
                                    <ul className="sub-menu--mega__list__second-level">
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/ACIDE%20HYALURONIQUE/FILLERS/PLURYAL%20CLASSIC">
                                                Pluryal Classic
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/ACIDE%20HYALURONIQUE/FILLERS/PLURYAL%20VOLUME">
                                                Pluryal Volume
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/ACIDE%20HYALURONIQUE/FILLERS/PLURYAL%20LIDOCAINE%20CLASSIC">
                                                Pluryal Lidocaïne Classic
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/ACIDE%20HYALURONIQUE/FILLERS/PLURYAL%20LIDOCAINE%20VOLUME">
                                                Pluryal Lidocaïne Volume
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/catalogue/SOLUTIONS%20REGENERATRICES/ACIDE%20HYALURONIQUE/FILLERS/PLURYAL%20BIOVOLUME">
                                                Pluryal Biovolume
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/catalogue/SOLUTIONS%20REGENERATRICES/ACIDE%20HYALURONIQUE/CANULES">
                                        Canules
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>);
};

export default NewCatalogue;
