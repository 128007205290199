import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useHistory, useLocation} from "react-router";
import {usePostLoginLumenMutation} from "../../app/services/lumenApi";
import Spinner from "../../common/components/spinner/Spinner";
import {clearCart, clearShippingAddress} from "../cart/cartSlice";
import {clearWishlist} from "../wishlist/wishlistSlice";
import toast from "react-hot-toast";

const useQuery = () => {
    const {search} = useLocation();
    return search.split("?token=")[1];
};

const LoginSSO = () => {
    const [login] = usePostLoginLumenMutation();

    const dispatch = useDispatch();
    const urlToken = useQuery();

    const {push} = useHistory();

    useEffect(() => {
        login({
            token: urlToken
        })
            .unwrap()
            .then((payload) => {
                if (payload.success === 1) {
                    dispatch(clearShippingAddress());
                    dispatch(clearCart());
                    dispatch(clearWishlist());
                    push("/");
                }
            })
            .catch((error) => {
                push("/login")
                toast.error('Vos identifiants n\'ont pas pu être validés.')
            })
    }, []);

    return <Spinner/>;
};

export default LoginSSO;
