import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router";
import {cartSelector, getTotals} from "../cartSlice";
import {Container} from "@mui/material";
import LDACartTable from "./LDACartTable";
import styles from "../cart.module.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const LDACart = () => {
    const cart = useSelector(cartSelector);

    const {push} = useHistory();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTotals());
    }, [cart, dispatch]);

    const {idsf, company} = useParams();

    return (
        <Container maxWidth="xl">
            <div className={styles.goBackLda} onClick={() => push("/profile/clients")}>
                <ArrowBackIosNewIcon/> Sélectionner un autre client
            </div>
            <LDACartTable products={cart.cartItems} total={cart.cartTotalAmount} idsf={idsf} company={company}/>
        </Container>
    );
};

export default LDACart;
