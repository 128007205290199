import React, {useState} from "react";
import {Field, Form} from "react-final-form";
import toast from "react-hot-toast";
import {useHistory} from "react-router";
import {usePostAdminLoginMutation} from "../../app/services/lumenApi";
import SubmitButton from "../../common/components/buttons/SubmitButton";
import styles from "./login.module.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {IconButton} from "@mui/material";

const AdminLogin = () => {
    const {push} = useHistory();

    const [login] = usePostAdminLoginMutation();

    const [showPassword, setShowPassord] = useState(false);

    const handleShowPassword = () => {
        setShowPassord(!showPassword);
    };

    const onSubmit = async (values) => {
        try {
            await login(values).unwrap();
            push("/profile/account");
        } catch (error) {
            toast.error('Vos identifiants n\'ont pas pu être validés.')
        }
    };

    return (
        <div className={styles.loginContainer}>
            <div className={styles.login}>
                <h2 className={styles.title}>Connexion</h2>

                <Form
                    onSubmit={onSubmit}
                    validate={(values) => {
                        const errors = {};
                        if (!values.email) {
                            errors.email = "Ce champs est requis";
                        }
                        if (!values.password) {
                            errors.password = "Ce champs est requis";
                        }
                        return errors;
                    }}
                    render={({submitError, handleSubmit, submitting}) => (
                        <form onSubmit={handleSubmit} className={styles.loginFormContainer}>
                            <div className={styles.loginForm}>
                                <Field name="email" type="email">
                                    {({input, meta}) => (
                                        <div className={styles.inputContainer}>
                      <span className={styles.inputSpan}>
                        <input {...input} type="text" placeholder="*Email"/>
                      </span>{" "}
                                            {(meta.error || meta.submitError) && meta.touched && (
                                                <span className={styles.errorText}>
                          {meta.error || meta.submitError}
                        </span>
                                            )}
                                        </div>
                                    )}
                                </Field>
                                <Field name="password">
                                    {({input, meta}) => (
                                        <div className={styles.inputContainer}>
                      <span className={styles.inputSpan}>
                        <input
                            {...input}
                            type={showPassword ? "text" : "password"}
                            placeholder="*Mot de passe"
                        />
                        <IconButton onClick={handleShowPassword}>
                          {showPassword ? (
                              <VisibilityOffIcon/>
                          ) : (
                              <VisibilityIcon/>
                          )}
                        </IconButton>
                      </span>{" "}
                                            {meta.error && meta.touched && (
                                                <span className={styles.errorText}>{meta.error}</span>
                                            )}
                                        </div>
                                    )}
                                </Field>
                                {submitError && <div className="error">{submitError}</div>}{" "}
                                <div className={styles.buttons}>
                                    <SubmitButton
                                        buttonText="Me connecter"
                                        disabled={submitting}
                                        type="submit"
                                        buttonStyle="dark"
                                    />
                                </div>
                            </div>
                        </form>
                    )}
                />
            </div>
        </div>
    );
};

export default AdminLogin;
