import React from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import styles from "./needHelp.module.css";

const NeedHelp = () => {
    return (
        <div className={styles.help}>
            <h3 className={styles.title}>Besoin d'aide ?</h3>
            <div>
                <div className={styles.contactContainer}>
                    Un assistant des ventes à votre disposition du lundi au vendredi de
                    8h30 à 18h.
                </div>
                <div className={styles.contactDiv}>
                    <a href="tel:+33 4 86 17 60 00" className={styles.contactLink}>
                        <PhoneIcon/> 04 86 17 60 00
                    </a>
                </div>
                <div className={styles.contactDiv}>
                    <a
                        href="mailto:hotline-prothese@biotech-dental.com"
                        className={styles.contactLink}
                    >
                        <MailOutlineIcon/> hotline-prothese@biotech-dental.com
                    </a>
                </div>
            </div>
        </div>
    );
};

export default NeedHelp;
