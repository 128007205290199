import {useDispatch, useSelector} from "react-redux";
import {authSelector} from "../auth/authSlice";
import {useGetSmileysQuery} from "../../app/services/x3Api";
import {addDiscount, cartSelector, removeDiscount} from "../cart/cartSlice";
import styles from "./addressForm.module.css";
import SubmitButton from "../../common/components/buttons/SubmitButton";
import React from "react";
import toast from "react-hot-toast";

const Smileys = () => {
    const {user} = useSelector(authSelector);

    const {data, isFetching, isError, error} = useGetSmileysQuery(
        user.suid
    );

    const cart = useSelector(cartSelector);

    const dispatch = useDispatch();

    if (isFetching) return <></>;

    if (!data) return <div className={styles.address}>Aucun Smileys</div>;

    if (isError) return <div>{error}</div>;

    const handleUseSmileys = () => {
        if (cart.discount > 0) {
            dispatch(removeDiscount())
        } else {
            if (data.result < 500 || data.result < cart.cartTotalWithoutDiscount) {
                toast.error('Votre solde de Smileys est insuffisant. Il doit être supérieur à 500 Smileys et supérieur au montant total de la commande hors remises.')

            } else {
                dispatch(addDiscount(Math.ceil(cart.cartTotalWithoutDiscount)))
            }
        }

    }
    let text = cart.discount > 0 ? "Supprimer la remise" : "Utiliser mes Smileys"

    return (
        <div className={styles.address}>
            {data.code && data.code === "ERR-SMY-002" ? (
                <h3 className="mb-2">Mon solde de Smileys : <small>Utilisateur Galaxy non reconnu</small></h3>
            ) : (
                <>
                    <h3 className="mb-2">Mon solde de Smileys : {data.result - cart.discount}</h3>
                    <div className={styles.inputContainer}>
                        <div className={styles.addAddress}>
                            {data.result >= 0 &&
                                <SubmitButton
                                    buttonText={text}
                                    onClick={handleUseSmileys}
                                />
                            }
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Smileys;
