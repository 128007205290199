import React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from "@mui/icons-material/Person";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import {Box} from "@mui/system";
import Profile from "./Profile";
import Bills from "../bills/Bills";
import Addresses from "../addresses/Addresses";
import WishlistList from "../wishlist/WhishlistList";
import ShipmentsList from "../shipments/ShipmentsList";
import LogoutIcon from "@mui/icons-material/Logout";
import Credits from "../credits/Credits";
import DescriptionIcon from "@mui/icons-material/Description";
import {useHistory, useParams} from "react-router";
import {Link} from "react-router-dom";
import styles from "./profileDashboard.module.css";
import {authSelector, logout} from "../auth/authSlice";
import {useDispatch, useSelector} from "react-redux";
import {clearCart} from "../cart/cartSlice";
import {clearWishlist} from "../wishlist/wishlistSlice";
import {clearProducts} from "../products/ProductsSlice";
import PeopleIcon from '@mui/icons-material/People';
import LabClient from "../network/LabClients";
import ShipmentListLDA from "../shipments/LDA/ShipmentListLDA";

const drawerWidth = 240;

const navLinks = [
    {
        link: "account",
        icon: <PersonIcon/>,
        title: "Mon profil",
    },
    {
        link: "wishlist",
        icon: <FavoriteIcon/>,
        title: "Mes whishlists",
    },
    {
        link: "shipments",
        icon: <LocalShippingIcon/>,
        title: "Mes commandes",
    },
    {
        link: "credits",
        icon: <AttachMoneyIcon/>,
        title: "Mes crédits",
    },
    {
        link: "bills",
        icon: <DescriptionIcon/>,
        title: "Mes factures",
    },
    {
        link: "addresses",
        icon: <ContactMailIcon/>,
        title: "Carnet d'adresses",
    },
];

const navLinksLab = [
    {
        link: "account",
        icon: <PersonIcon/>,
        title: "Mon profil",
    },
    {
        link: "wishlist",
        icon: <FavoriteIcon/>,
        title: "Mes whishlists",
    },
    {
        link: "clients",
        icon: <PeopleIcon/>,
        title: "Mes clients",
    },
    {
        link: "shipments",
        icon: <LocalShippingIcon/>,
        title: "Mes commandes",
    },
    {
        link: "credits",
        icon: <AttachMoneyIcon/>,
        title: "Mes crédits",
    },
    {
        link: "bills",
        icon: <DescriptionIcon/>,
        title: "Mes factures",
    },
    {
        link: "addresses",
        icon: <ContactMailIcon/>,
        title: "Carnet d'adresses",
    },
];

const navLinksAdmin = [
    {
        link: "clients",
        icon: <PeopleIcon/>,
        title: "Mes clients",
    },
    {
        link: "shipments-lda",
        icon: <LocalShippingIcon/>,
        title: "Mes commandes",
    },
];


const ProfileDashboard = () => {
    const {tab} = useParams();

    const dispatch = useDispatch();

    const {push} = useHistory();

    const {user} = useSelector(authSelector);

    const handleLogout = () => {
        dispatch(logout());
        dispatch(clearCart());
        dispatch(clearWishlist());
        dispatch(clearProducts());
        push("/");
    };

    const links = user.user_type === "LDA" ? navLinksAdmin : user.user_type === "LABORATOIRE" ? navLinksLab : navLinks;

    return (
        <Box sx={{display: "flex", justifyContent: "center"}}>
            <Drawer
                sx={{
                    width: drawerWidth,
                    display: {xs: 'none', sm: 'none', md: 'block'},
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                        position: "inherit",
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <List>
                    {links.map((link, index) => (
                        <ListItem key={index}>
                            <Link
                                to={`/profile/${link.link}`}
                                className={tab === link.link ? styles.selected : styles.link}
                            >
                                <ListItemIcon>{link.icon}</ListItemIcon>
                                <ListItemText primary={link.title}/>
                            </Link>
                        </ListItem>
                    ))}
                    <ListItem key={5} onClick={handleLogout}>
                        <ListItemIcon>
                            <LogoutIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Se déconnecter" className={styles.logout}/>
                    </ListItem>
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    bgcolor: "background.default",
                    p: 3,
                    justifyContent: "center",
                }}
            >
                {tab === "account" ? (
                    <Profile/>
                ) : tab === "wishlist" ? (
                    <WishlistList/>
                ) : tab === "addresses" ? (
                    <Addresses/>
                ) : tab === "bills" ? (
                    <Bills/>
                ) : tab === "credits" ? (
                    <Credits/>
                ) : tab === "shipments" ? (
                    <ShipmentsList/>
                ) : tab === "clients" ? (
                    <LabClient/>
                ) : tab === "shipments-lda" ? (
                        <ShipmentListLDA/>
                    )
                    : (
                        "null"
                    )}
            </Box>
        </Box>
    );
};

export default ProfileDashboard;
