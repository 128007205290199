import React, {useState} from "react";
import {addLotLda, removeFromCartLda,} from "../cartSlice";
import {useDispatch} from "react-redux";
import toast from "react-hot-toast";
import styles from "../cartQuantityHandler.module.css";
import {Grid} from "@mui/material";
import LotSelectorLda from "./LotSelectorLda";
import CloseIcon from "@mui/icons-material/Close";

const CartQuantityHandlerLdaEmpty = ({
                                         cart,
                                         product,
                                         lot,
                                         index,
                                         handleRemoveRow
                                     }) => {

    const dispatch = useDispatch();

    const handleSelectLot = (product, e) => {
        const selected = e.target.value
        if (product.lot.find((l) => l.numero === e.target.value)) {
            return toast.error("Le lot a déjà été ajouté")
        }
        if (selected) {
            handleRemoveRow()
            dispatch(addLotLda({product: product, numero: e.target.value}))
        }
    }

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            className={`${styles.cartTableRow} ${product.reference}`}
            key={index}
        >
            <Grid item xs={3} className={styles.commentField}>
                <LotSelectorLda handleSelectLot={handleSelectLot} product={product} lot={lot}/>
            </Grid>
            <Grid item xs={8}>
            </Grid>
            <Grid item xs={1} className={styles.txtCenter}>
                {cart.isLoadingQuantity ? (
                    <></>
                ) : (
                    <button
                        className={styles.deleteButton}
                        onClick={handleRemoveRow}
                    >
                        <CloseIcon/>
                    </button>
                )}
            </Grid>
        </Grid>
    );
};
export default CartQuantityHandlerLdaEmpty;
